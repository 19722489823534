var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { validatePromocode } from '@/entity/PromocodesEntity/promocodes.slice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
export const usePromocodeActive = (promoCode, type) => {
    const dispatch = useTypedDispatch();
    const [handleSuccess, setHandleSuccess] = useState(false);
    const [handleFail, setHandleFail] = useState(false);
    const [havePromocode, setHavePromoCode] = useState(false);
    const handleValidatePromocode = () => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield dispatch(validatePromocode({ name: promoCode.trim(), type: type }));
        if (data.payload.code) {
            setHandleFail(true);
            setHandleSuccess(false);
        }
        else {
            setHandleSuccess(true);
            setHandleFail(false);
        }
        return data;
    });
    const handleCancelPromocode = () => {
        setHandleSuccess(false);
        setHandleFail(false);
        setHavePromoCode(false);
    };
    return {
        havePromocode,
        handleFail,
        handleSuccess,
        setHavePromoCode,
        handleValidatePromocode,
        handleCancelPromocode,
    };
};
