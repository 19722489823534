var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, Input, Space, Text } from '@/shared/ui';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useState } from 'react';
import { setMailingBalanceForUser } from '@/entity/PromocodesEntity/promocodes.slice';
import { ToastSuccess } from '@/widgets/ui/Toast/ToastSuccess/ToastSuccess';
const SetMailing = ({ setOpen }) => {
    //Todo: Доделать условия, создание инвайткода, в sideBar сделать сворачивание у блока Админ
    const dispatch = useTypedDispatch();
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const handleSetBalance = () => __awaiter(void 0, void 0, void 0, function* () {
        const resp = yield dispatch(setMailingBalanceForUser({ email, mailingBalance: amount }));
        if (!resp.payload.code) {
            ToastSuccess(`Пользователю ${email} успешно пополнен баланс на ${amount} сообщений. Текущий баланс ${resp.payload.balance.mailingBalance}`);
            setEmail('');
            setAmount('');
        }
    });
    const handleChangeUserId = (event) => {
        setEmail(event.target.value);
    };
    const handleChangeAmount = (event) => {
        setAmount(event.target.value.trim());
    };
    return (_jsx(Dialog, { open: true, closeIcon: true, css: {
            width: '560px',
        }, onOpenChange: () => setOpen(false), children: _jsxs(Space, { css: { height: '100%' }, size: "middle", content: "between", direction: "vertical", children: [_jsxs(Space, { size: "middle", direction: "vertical", children: [_jsx(Text, { type: "preTitle", children: "\u041D\u0430\u0447\u0438\u0441\u043B\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F" }), _jsx(Input, { value: email, typeInput: "default", css: { height: '40px' }, placeholder: "Email", onChange: handleChangeUserId, label: "Email \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F", labelFontFamily: "nunito" }), _jsx(Input, { cssContainer: { width: '40%' }, value: amount, onChange: handleChangeAmount, css: { height: '40px' }, typeInput: "default", placeholder: "\u041A\u043E\u043B\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439", labelFontFamily: "nunito", label: "\u041D\u0430\u0447\u0438\u0441\u043B\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439" })] }), _jsx(Space, { content: "flexEnd", children: _jsx(Button, { onClick: handleSetBalance, type: "primary", children: "\u041D\u0430\u0447\u0438\u0441\u043B\u0438\u0442\u044C" }) })] }) }));
};
export { SetMailing };
