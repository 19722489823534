import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ErrorBoundaryImg from '@/shared/assets/icons/ErrorBoundary.png';
import { Button, Dialog, Space, Text } from '@/shared/ui';
import { ReactComponent as Chat } from '@/shared/assets/icons/chat.svg';
import { SupportButton } from '@/features/ui/SupportButton/SupportButton';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.handleReload = () => {
            window.location.reload();
        };
        this.handleGoHome = () => {
            this.props.history.push('/');
        };
        this.state = {
            hasError: false,
            errorMessage: '',
            isChunkLoadError: false,
        };
    }
    static getDerivedStateFromError(error) {
        // Обновляем состояние, чтобы отобразить интерфейс ошибки
        return {
            hasError: true,
            errorMessage: error.message,
            isChunkLoadError: /Loading chunk \d+ failed/.test(error.message),
        };
    }
    componentDidCatch(error, errorInfo) {
        return {
            hasError: true,
            errorMessage: error.message,
            isChunkLoadError: /Loading chunk \d+ failed/.test(error.message),
        };
    }
    render() {
        if (this.state.hasError) {
            if (this.state.isChunkLoadError) {
                return (_jsxs(_Fragment, { children: [_jsxs(Dialog, { open: true, outside: false, closeIcon: false, children: [_jsx(Text, { type: "preTitle", css: { marginBottom: '12px' }, children: "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0435 \u043D\u043E\u0432\u043E\u0439 \u0432\u0435\u0440\u0441\u0438\u0438 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044F." }), _jsx(Text, { css: { marginBottom: '24px' }, type: "default", children: "\u0414\u043B\u044F \u043A\u043E\u0440\u0435\u0442\u043D\u043E\u0439 \u0440\u0430\u0431\u043E\u0442\u044B \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043E\u0431\u043D\u043E\u0432\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443." }), _jsx("div", { children: _jsx(Button, { onClick: this.handleReload, children: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443" }) })] }), this.props.children] }));
            }
            return (_jsxs(Space, { css: { height: '100%' }, direction: "vertical", content: "center", align: "center", size: "default", children: [_jsx("img", { src: ErrorBoundaryImg, style: { width: '87px', height: '85px' }, alt: "icon" }), _jsx(Text, { type: "preTitle", children: "\u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A." }), this.state.errorMessage && _jsx(Text, { css: { margin: '10px 0' }, type: "labelText", children: this.state.errorMessage }), _jsxs(Space, { size: "default", children: [_jsx(Button, { onClick: this.handleGoHome, children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E" }), _jsx(SupportButton, { children: _jsx(Button, { icon: Chat, type: "default", withoutBorder: true, children: "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443" }) })] })] }));
        }
        return this.props.children;
    }
}
export default withRouter(ErrorBoundary);
