import * as yup from 'yup';
export const regExpPhone = /^(\+?[78])\d{10}$/;
export const personalDataSchemaWithName = yup.object({
    name: yup
        .string()
        .min(1, { text: '' })
        .required({ text: 'Обязательное поле' }),
    surName: yup
        .string()
        .min(1, { text: '' })
        .required({ text: 'Обязательное поле' }),
    phone: yup
        .string()
        .test({
        name: 'phone',
        message: { text: '' },
        test: ((value) => regExpPhone.test(value)),
    })
        .required({ text: 'Обязательное поле' }),
});
export const personalDataSchemaWithoutName = yup.object({
    phone: yup
        .string()
        .test({
        name: 'phone',
        message: { text: '' },
        test: ((value) => regExpPhone.test(value)),
    })
        .required({ text: 'Обязательное поле' }),
});
