import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import React from 'react';
const CollapseButton = ({ open, setOpen, sideBarHead }) => {
    return (_jsx(S.IconItem, { "data-state-open": open, onClick: (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (setOpen) {
                setOpen(event);
            }
        }, children: _jsx(S.IconCollapse, { children: _jsx("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("g", { opacity: "0.2", children: _jsx("path", { d: "M12.75 8.25L9 4.5L5.25 8.25M12.75 13.5L9 9.75L5.25 13.5", stroke: "#3C435A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }) }) }) }));
};
export { CollapseButton };
