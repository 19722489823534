import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from '@/widgets/ui/SideBar/style';
import { Space } from '@/shared/ui/Space/Space';
import mainLogo from '@/shared/assets/icons/mainLogo.svg';
import closeLogo from '@/shared/assets/icons/CloseLogo.svg';
import { ReactComponent as Loader } from '@/shared/assets/icons/loader.svg';
import { ReactComponent as Calculator } from '@/shared/assets/icons/calculator.svg';
import { ReactComponent as Card } from '@/shared/assets/icons/card.svg';
import { ReactComponent as Chat } from '@/shared/assets/icons/chat.svg';
import { ReactComponent as Admin } from '@/shared/assets/icons/AdminMain.svg';
import { ReactComponent as Exit } from '@/shared/assets/icons/exit.svg';
import { ReactComponent as MessageHistory } from '@/shared/assets/icons/messageHistory.svg';
import { CollapseButton } from '@/shared/ui/CollapseButton/CollapseButton';
import { CustomScroll } from '@/shared/ui/CustomScroll/CustomScroll';
import { routes } from '@/shared/utils/routes';
import { Icon } from '@/shared/ui/Icon/Icon';
import { Divider } from '@/shared/ui/Divider/Divider';
import firstBlockButtons from '@/widgets/ui/SideBar/firstBlockButtons';
import SideBarButton from '@/features/ui/SideBar/ui/SideBarButton/SideBarButton';
import thirdBlockButtons from '@/widgets/ui/SideBar/thirdBlockButtons';
import { Button } from '@/shared/ui/Button/Button';
import secondBlockButtons from '@/widgets/ui/SideBar/secondBlockButtons';
import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { NavLink, Redirect, useLocation } from 'react-router-dom';
import { useSideBarFlow } from '@/shared/hooks/useSideBarFlow/useSideBarFlow';
import { SupportButton } from '@/features/ui/SupportButton/SupportButton';
import { ProductTypeEnum } from '@/app/interfaces/Mailings/types';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setCalculatorSideBarModule, setMailingsSideBarModule } from '@/entity/SidebarEntity/sidebar.slice';
import adminBlockButtons from '@/widgets/ui/SideBar/adminBlockButtons';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
const cookies = new Cookies();
const Navigation = ({ setIsOpened, isOpened, isDesktop }) => {
    const { pathname } = useLocation();
    const dispatch = useTypedDispatch();
    const { toggleNav, handleButtonClick, mainSideBarModule, setSideBarMailingModule, setSideBarMainModule, setSideBarCalculatorModule, profile, logout, openAddProductModal, currentSub, calculatorSideBarModule, mailingsSideBarModule, notification, isAdmin, changeMailingUserBalance, handleBuyMailing, } = useSideBarFlow(setIsOpened, isDesktop);
    const checkActiveForMailing = () => {
        var _a;
        const findIndexElement = (_a = profile === null || profile === void 0 ? void 0 : profile.products) === null || _a === void 0 ? void 0 : _a.findIndex((el) => el === ProductTypeEnum.USER_ACTIVE_PRODUCT_MAILING);
        return Number(findIndexElement) === -1;
    };
    const checkActiveCalculator = () => {
        var _a;
        const findIndexElement = (_a = profile === null || profile === void 0 ? void 0 : profile.products) === null || _a === void 0 ? void 0 : _a.findIndex((el) => el === ProductTypeEnum.USER_ACTIVE_PRODUCT_CALCULATOR);
        return Number(findIndexElement) === -1;
    };
    const checkActiveForCalculator = () => {
        return currentSub !== null;
    };
    useEffect(() => {
        if (profile) {
            if (checkActiveCalculator()) {
                dispatch(setCalculatorSideBarModule({ value: false }));
            }
            else {
                dispatch(setCalculatorSideBarModule({ value: true }));
            }
            if (checkActiveForMailing()) {
                dispatch(setMailingsSideBarModule({ value: false }));
            }
        }
    }, [profile, currentSub]);
    if (!cookies.get('token')) {
        return _jsx(Redirect, { to: "/login" });
    }
    return (_jsxs(_Fragment, { children: [_jsx(S.SideBarContent, { "data-state-open": isOpened, children: _jsx(S.SideBarWrapper, { children: _jsxs(S.SideBarBodyWrapper, { children: [_jsx(S.SideBarBodyIconWrapper, { children: _jsxs(Space, { css: {
                                        width: '100%',
                                        alignItems: isOpened ? '' : 'center',
                                    }, content: "between", children: [_jsx(S.SideBarIcon, { css: {
                                                marginBottom: isOpened ? '8px' : '0px',
                                                marginRight: isOpened ? '0' : '13px',
                                            }, src: !isDesktop ? mainLogo : isOpened ? mainLogo : closeLogo, alt: "logo" }), _jsx(S.ToggleButton, { id: "sideBarToggle", "data-state-open": isOpened, onClick: toggleNav, children: _jsx(S.ToggleIconCollapse, { children: _jsx("path", { xmlns: "http://www.w3.org/2000/svg", d: "M1.25 6.75L5 10.5L8.75 6.75M1.25 1.5L5 5.25L8.75 1.5", stroke: "#3C435A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }) })] }) }), _jsx(S.SideBarScrollWrapper, { isClosed: !isOpened, children: _jsxs(CustomScroll, { children: [_jsxs(S.SideBarBodySectionWrapper, { children: [_jsx(NavLink, { to: routes.main, children: _jsxs(S.SideBarBodySectionButton, { "data-state-open": mainSideBarModule, active: pathname === routes.main, children: [_jsxs(S.SideBarSectionButtonRow, { onClick: () => handleButtonClick(routes.main), children: [_jsx(Icon, { active: pathname === routes.main, IconItem: MessageHistory, fill: "sideBar", sideBarHover: "sideBar" }), _jsx(S.SideBarBodySectionButtonTextMain, { children: "\u0413\u043B\u0430\u0432\u043D\u0430\u044F" })] }), _jsx(CollapseButton, { open: mainSideBarModule, setOpen: setSideBarMainModule })] }) }), mainSideBarModule && _jsx(Divider, {}), _jsx(S.SideBarButtonContainer, { isOpen: mainSideBarModule, children: firstBlockButtons.map((button) => {
                                                        var _a;
                                                        return (_jsx("div", { style: { paddingLeft: '32px' }, children: ((_a = button === null || button === void 0 ? void 0 : button.role) === null || _a === void 0 ? void 0 : _a.includes(profile === null || profile === void 0 ? void 0 : profile.kind)) &&
                                                                _jsx(NavLink, { to: button.route || '', children: _jsx(SideBarButton, { notification: button.notification && notification, open: mainSideBarModule, active: pathname === button.route, text: button.text, IconItem: button.icon, isMain: button === null || button === void 0 ? void 0 : button.isMain, handleButtonClick: () => handleButtonClick(button.route) }, button.id) }) }, button.id));
                                                    }) })] }), _jsxs(S.SideBarBodySectionWrapper, { children: [_jsx(NavLink, { to: routes.homeMailings, children: _jsxs(S.SideBarBodySectionButton, { "data-state-open": mailingsSideBarModule, active: pathname === routes.homeMailings, children: [_jsxs(S.SideBarSectionButtonRow, { children: [_jsx(Icon, { IconItem: MessageHistory, active: pathname === routes.homeMailings, fill: "sideBar", sideBarHover: "sideBar" }), _jsx(S.SideBarBodySectionButtonTextMain, { active: pathname === routes.homeMailings, onClick: () => handleButtonClick(routes.homeMailings), children: "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0438" })] }), !checkActiveForMailing() &&
                                                                _jsx(CollapseButton, { open: mailingsSideBarModule, setOpen: setSideBarMailingModule })] }) }), mailingsSideBarModule && _jsx(Divider, {}), _jsxs(S.SideBarButtonContainer, { isHidden: checkActiveForMailing(), isOpen: mailingsSideBarModule, children: [thirdBlockButtons.map((button) => (_jsx("div", { style: { paddingLeft: '32px' }, children: _jsx(NavLink, { to: button.route || '', children: _jsx(SideBarButton, { open: mailingsSideBarModule, active: pathname === button.route || (pathname === null || pathname === void 0 ? void 0 : pathname.includes(button.active)), text: button.text, IconItem: button.icon, isMain: button.isMain, pathname: pathname, route: button.route, handleButtonClick: () => handleButtonClick(button.route) }, button.id) }) }, button.id))), _jsx(S.PrimarySideBarButtonVisible, { visible: mailingsSideBarModule, children: _jsx(Button, { fill: "white", onClick: handleBuyMailing, type: "primary", icon: Card, children: "\u041F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0431\u0430\u043B\u0430\u043D\u0441" }) })] })] }), _jsxs(S.SideBarBodySectionWrapper, { children: [_jsx(NavLink, { to: routes.home, children: _jsxs(S.SideBarBodySectionButton, { active: pathname === routes.home, "data-state-open": calculatorSideBarModule, children: [_jsxs(S.SideBarSectionButtonRow, { children: [_jsx(Icon, { IconItem: Calculator, active: pathname === routes.home, fill: "sideBar", sideBarHover: "sideBar" }), _jsx(S.SideBarBodySectionButtonTextMain, { onClick: () => handleButtonClick(routes.home), active: pathname === routes.home, children: "\u041A\u0430\u043B\u044C\u043A\u0443\u043B\u044F\u0442\u043E\u0440" })] }), checkActiveForCalculator() &&
                                                                _jsx(CollapseButton, { open: calculatorSideBarModule, setOpen: setSideBarCalculatorModule })] }) }), calculatorSideBarModule && _jsx(Divider, {}), _jsxs(S.SideBarButtonContainer, { isHidden: !checkActiveForCalculator(), isOpen: calculatorSideBarModule, children: [secondBlockButtons.map((button) => (_jsx("div", { style: { paddingLeft: '32px' }, children: _jsx(NavLink, { to: button.route, children: _jsx(SideBarButton, { open: calculatorSideBarModule, active: pathname === null || pathname === void 0 ? void 0 : pathname.includes(button.route), text: button.text, IconItem: button.icon, pathname: pathname, route: button.route, handleButtonClick: () => handleButtonClick(button.route) }, button.id) }) }, button.id))), _jsx(S.PrimarySideBarButtonVisible, { visible: calculatorSideBarModule, children: _jsx(Button, { onClick: openAddProductModal, type: "primary", stroke: "$white", icon: Loader, children: "\u0410\u043D\u0430\u043B\u0438\u0437\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0442\u043E\u0432\u0430\u0440" }) })] }), isAdmin &&
                                                    _jsxs(S.SideBarBodySectionWrapper, { children: [_jsx(NavLink, { to: RoutePath.homeAdmin, children: _jsx(S.SideBarBodySectionButton, { active: pathname === RoutePath.homeAdmin, children: _jsxs(S.SideBarSectionButtonRow, { onClick: () => handleButtonClick(RoutePath.homeAdmin), children: [_jsx(Icon, { active: pathname === RoutePath.homeAdmin, IconItem: Admin, sideBarHover: "sideBar" }), _jsx(S.SideBarBodySectionButtonTextMain, { children: "\u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440" })] }) }) }), mainSideBarModule && _jsx(Divider, {}), _jsx(S.SideBarButtonContainer, { isOpen: true, children: adminBlockButtons.map((button) => {
                                                                    var _a;
                                                                    return (_jsx("div", { style: { paddingLeft: '32px' }, children: ((_a = button === null || button === void 0 ? void 0 : button.role) === null || _a === void 0 ? void 0 : _a.includes(profile === null || profile === void 0 ? void 0 : profile.kind)) &&
                                                                            _jsx(NavLink, { to: (button === null || button === void 0 ? void 0 : button.route) || pathname, children: _jsx(SideBarButton, { notification: button.notification && notification, open: true, active: pathname === button.route, text: button.text, IconItem: button.icon, isMain: button === null || button === void 0 ? void 0 : button.isMain, handleButtonClick: () => {
                                                                                        if (button.id === 'add-messages') {
                                                                                            changeMailingUserBalance();
                                                                                        }
                                                                                        handleButtonClick(button === null || button === void 0 ? void 0 : button.route);
                                                                                    } }, button.id) }) }, button.id));
                                                                }) })] })] })] }) }), isOpened &&
                                _jsxs(S.SideBarFooter, { children: [_jsx(SupportButton, { children: _jsx(Button, { icon: Chat, type: "default", withoutBorder: false, children: "\u041F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0430" }) }), _jsx(Button, { onClick: () => logout(), type: "danger", icon: Exit, children: "\u0412\u044B\u0439\u0442\u0438" })] })] }) }) }), isDesktop ? !isOpened &&
                _jsxs(S.CloseSideBarFooter, { children: [_jsx(SupportButton, { side: "bottom", children: _jsx(Button, { type: "default", withoutBorder: false, css: { padding: '6px', width: '40px', height: '34px' }, children: _jsx(Chat, {}) }) }), _jsx(Button, { type: "danger", css: { padding: '6px', width: '40px', height: '34px' }, onClick: logout, children: _jsx(Exit, {}) })] }) : null] }));
};
export { Navigation };
