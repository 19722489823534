export const routes = {
    any: '/',
    profile: '/profile',
    home: '/home',
    login: '/login',
    signUp: '/signUp',
    main: '/main',
    registerToBeta: '/registerBeta',
    myMailings: '/myMailings',
    newMailing: '/newMailing',
    myShops: '/myShops',
    homeMailings: '/homeMailings',
    mailing: '/mailing/:id',
    savedProducts: '/products',
    calculationsList: '/calculations',
    calculation: '/calculations/:id',
    product: '/products/:id',
    productWithoutSave: '/products/0000-0000-0000-0000-0000',
    calculateNewItem: '/calculateNewItem',
    promocodes: '/promocodes',
    invitecodes: '/invitecodes',
};
export const routeGenerator = {
    mailing: (id) => `${routes.mailing.replace(':id', id)}`,
    calculate: (id) => `${routes.calculation.replace(':id', id)}`,
    product: (id) => `${routes.product.replace(':id', id)}`,
};
