import { styled } from '@/app/styles/mainStyles';
export const SideBarBodySectionButtonWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 4px',
    cursor: 'pointer',
    marginBottom: '8px',
    marginRight: '8px',
    '&:hover': {
        background: 'rgba(236, 236, 236, 1)',
        borderRadius: '8px',
    },
    variants: {
        active: {
            true: {
                background: 'rgba(84, 95, 201, 0.2)',
                borderRadius: '8px',
                '&:hover': {
                    background: 'rgba(84, 95, 201, 0.2)',
                    borderRadius: '8px',
                },
            },
        },
        display: {
            false: {
                visibility: 'hidden',
            },
        },
    },
});
export const SideBarBodySectionButtonRow = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
});
export const NotificationPoint = styled('div', {
    width: '8px',
    height: '8px',
    background: '$error',
    marginRight: '8px',
    borderRadius: '16px',
});
export const SideBarBodySectionButtonText = styled('div', {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#414BB2',
    variants: {
        active: {
            true: {
                color: '#2A3389',
            },
        },
    },
    [`${SideBarBodySectionButtonWrapper}:hover &`]: {
        color: '#545FC9 !important',
    },
});
