import { styled } from '@/app/styles/mainStyles';
import { Input } from '@/shared/ui/Input/style';
import { Text } from '@/shared/ui/Text/style';
export const MailingInput = styled(Input, {
    width: '82px',
    margin: '0 10px',
    color: '$scrollColor',
    fontSize: '17px',
    fontWeight: 'bold',
    fontFamily: '$inter',
});
export const AbsoluteNewYearIcon = styled('div', {
    position: 'absolute',
    width: '293px',
    right: 0,
    top: 0,
});
export const PresetContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    border: '1px solid $border',
    borderRadius: '8px',
    alignItems: 'center',
    background: '$white',
    gap: '12px',
    marginBottom: '24px',
});
export const GraphContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '$deliveryBackground',
});
export const RateBlock = styled('div', {
    borderRadius: '8px',
    background: '$deliveryBackground',
    padding: '8px 16px',
    display: 'flex',
    border: '1px solid $border',
    gap: '12px',
});
export const RequestTypeContainer = styled('div', {
    display: 'flex',
    width: '100%',
    maxWidth: '860px',
    background: '$deliveryBackground',
    padding: '6px',
    border: '1px solid $border',
    '@desktop': {
        justifyContent: 'space-between',
        borderRadius: '48px',
        height: '43px',
    },
    '@mobile': {
        flexDirection: 'column',
        boxSizing: 'border-box',
        borderRadius: '16px',
        height: '100%',
    },
});
export const RequestTypeItem = styled('div', {
    display: 'flex',
    padding: '6px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: '$inter',
    color: '$disableColor',
    maxHeight: '31px',
    cursor: 'pointer',
    borderRadius: '40px',
    '@mobile': {
        width: '100%',
    },
    '@desktop': {
        width: '50%',
    },
    variants: {
        active: {
            true: {
                backgroundColor: '$active',
                color: '$white',
            },
        },
        disable: {
            true: {
                cursor: 'not-allowed',
                backgroundColor: '$disableBackground',
                color: '$disableColor',
            },
        },
    },
});
export const InterText = styled('b', {
    fontFamily: '$inter',
});
export const GraphItemContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    variants: {
        type: {
            current: {
                width: '200%',
            },
            before: {
                width: '100%',
            },
            nextOne: {
                width: '150%',
            },
            next: {
                width: '100%',
                opacity: 0.7,
            },
        },
    },
});
export const GraphItem = styled('div', {
    height: '6px',
    borderRadius: '4px',
    width: '100%',
    variants: {
        type: {
            current: {
                backgroundColor: '$activeBackground',
                border: '1px solid $activeBackground',
            },
            before: {
                backgroundColor: '$border',
                border: '1px solid $border',
                [`${GraphItemContainer}:hover &`]: {
                    backgroundColor: '$activeBackground',
                    border: '1px solid $activeBackground',
                },
            },
            next: {
                backgroundColor: 'transparent',
                border: '1px solid $acceptButton',
                opacity: 0.7,
                [`${GraphItemContainer}:hover &`]: {
                    backgroundColor: '$acceptButton',
                    opacity: 1,
                },
            },
            nextOne: {
                backgroundColor: 'transparent',
                border: '1px solid $acceptButton',
                [`${GraphItemContainer}:hover &`]: {
                    backgroundColor: '$acceptButton',
                },
            },
        },
    },
});
export const TextGraph = styled(Text, {
    variants: {
        type: {
            current: {
                fontWeight: 700,
                fontSize: '13px',
                color: '$activeBackground',
            },
            before: {
                fontWeight: 400,
                fontSize: '9px',
                color: '$border',
            },
            next: {
                fontWeight: 400,
                fontSize: '9px',
                opacity: 0.7,
                color: '$acceptButton',
            },
            nextOne: {
                fontWeight: 700,
                fontSize: '13px',
                color: '$acceptButton',
            },
        },
    },
});
export const ContactInfo = styled('div', {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    border: '1px solid $border',
    borderRadius: '8px',
    background: '$deliveryBackground',
    gap: '16px',
});
export const RateMessage = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '50%',
});
