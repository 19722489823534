import * as yup from 'yup';
export const addNewShopSchema = yup.object({
    name: yup
        .string()
        .required({ text: 'Обязательное поле' })
        .min(2)
        .trim(),
    client_id: yup
        .string()
        .required({ text: 'Обязательное поле' }),
    client_secret: yup
        .string()
        .required({ text: 'Обязательное поле' }),
});
