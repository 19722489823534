import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BackgroundGray, Checkbox, Input, Space, Switch, Text } from '@/shared/ui';
import { useEffect } from 'react';
import { localeTime } from '@/shared/utils/subscriptionTimeLocale';
import { usePrepareTriffs } from '@/shared/hooks/usePrepareTariffs/usePrepareTriffs';
const CalculateBlock = ({ setActiveBlocks, activeBlocks, setPromocode, promocode }) => {
    var _a;
    const { filteredTariffs, setFilteredTariffs } = usePrepareTriffs();
    function handleChangeActiveTariffs(id) {
        if (promocode.productRuleCalculator.tariffIds.includes(id)) {
            setPromocode((prevState) => {
                return Object.assign(Object.assign({}, prevState), { productRuleCalculator: Object.assign(Object.assign({}, prevState.productRuleCalculator), { tariffIds: prevState.productRuleCalculator.tariffIds.filter((item) => item !== id) }) });
            });
        }
        else {
            setPromocode((prevState) => {
                return Object.assign(Object.assign({}, prevState), { productRuleCalculator: Object.assign(Object.assign({}, prevState.productRuleCalculator), { tariffIds: [...prevState.productRuleCalculator.tariffIds, id] }) });
            });
        }
    }
    const handleUpdatePercent = (event) => {
        const { value } = event.target;
        if (isNaN(+value)) {
            return;
        }
        setPromocode((prevState) => {
            return Object.assign(Object.assign({}, prevState), { productRuleCalculator: Object.assign(Object.assign({}, prevState.productRuleCalculator), { percent: +value }) });
        });
    };
    const handleUpdateSelectedItemsNewPrice = () => {
        setFilteredTariffs((prevState) => {
            return prevState.map((tariff) => {
                if (promocode.productRuleCalculator.tariffIds.includes(tariff.id)) {
                    const percent = isNaN(+promocode.productRuleCalculator.percent) ? 0 : +promocode.productRuleCalculator.percent;
                    return Object.assign(Object.assign({}, tariff), { newPrice: +tariff.price - +(+tariff.price * (percent / 100)).toFixed(0) });
                }
                return Object.assign(Object.assign({}, tariff), { newPrice: tariff.price });
            });
        });
    };
    console.log('filteredTariffs', filteredTariffs);
    useEffect(() => {
        handleUpdateSelectedItemsNewPrice();
    }, [promocode.productRuleCalculator, activeBlocks.calculate]);
    return (_jsxs(BackgroundGray, { css: { gap: '12px' }, children: [_jsxs(Space, { align: "center", content: "between", children: [_jsx(Text, { type: "preTitle", children: "\u041A\u0430\u043B\u044C\u043A\u0443\u043B\u044F\u0442\u043E\u0440" }), _jsx(Switch, { color: "green", checked: activeBlocks.calculate, setChecked: () => setActiveBlocks(Object.assign(Object.assign({}, activeBlocks), { calculate: !activeBlocks.calculate })) })] }), _jsxs(Space, { align: "center", size: "large", children: [_jsx(Text, { type: "default", children: "\u0421\u043A\u0438\u0434\u043A\u0430 \u043F\u043E \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434\u0443" }), _jsx(Input, { disabled: !activeBlocks.calculate, onChange: handleUpdatePercent, typeInput: "default", cssContainer: { width: '20%', height: '32px' }, value: (_a = promocode === null || promocode === void 0 ? void 0 : promocode.productRuleCalculator) === null || _a === void 0 ? void 0 : _a.percent })] }), _jsxs(Space, { children: [_jsx("div", { style: { width: '30px' } }), _jsx(Text, { css: { width: '50%', fontWeight: 300 }, type: "labelText", children: "\u0422\u0430\u0440\u0438\u0444" }), _jsx(Text, { css: { width: '25%', fontWeight: 300 }, type: "labelText", children: "\u0422\u0435\u043A\u0443\u0449\u0430\u044F \u0446\u0435\u043D\u0430" }), _jsx(Text, { css: { width: '25%', fontWeight: 300 }, type: "labelText", children: "\u0426\u0435\u043D\u0430 \u043F\u043E \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434\u0443" })] }), _jsx(Space, { size: "default", direction: "vertical", children: filteredTariffs.map((tariff) => {
                    var _a;
                    const disabled = (_a = promocode.productRuleCalculator) === null || _a === void 0 ? void 0 : _a.tariffIds.includes(tariff.id);
                    return (_jsxs(Space, { align: "center", children: [_jsx("div", { style: { width: '30px', flexShrink: 1, maxWidth: '30px' }, children: _jsx(Checkbox, { disabled: !activeBlocks.calculate, value: disabled, onChange: () => handleChangeActiveTariffs(tariff.id) }) }), _jsx(Text, { css: { width: '50%', fontWeight: 300 }, type: "default", disable: !disabled || !activeBlocks.calculate, children: `${tariff.name}, ${localeTime(tariff.duration)}` }), _jsx(Text, { css: { width: '25%', fontWeight: 300 }, disable: !disabled || !activeBlocks.calculate, children: tariff.price }), _jsx(Text, { css: { width: '25%', fontWeight: 300 }, disable: !disabled || !activeBlocks.calculate, children: tariff.newPrice })] }, tariff.id));
                }) })] }));
};
export { CalculateBlock };
