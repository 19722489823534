import { useEffect, useState } from 'react';
import { getPaymentTariffs } from '@/entity/PaymentEntity/payment.slice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
export const usePrepareTriffs = () => {
    const dispatch = useTypedDispatch();
    const tariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const [filteredTariffs, setFilteredTariffs] = useState([]);
    useEffect(() => {
        if (tariffs.length !== 0) {
            const filtered = tariffs.filter((tariff) => !(tariff.name === 'Рассылка' || tariff.name === 'Free'));
            const updated = [...filtered.map((el) => {
                    return Object.assign(Object.assign({}, el), { name: el.name.split(' ')[1], newPrice: el.price });
                })].sort((a, b) => { var _a; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare(b.name); });
            setFilteredTariffs(updated);
        }
        else {
            dispatch(getPaymentTariffs({}));
        }
    }, [tariffs]);
    return { filteredTariffs, setFilteredTariffs };
};
