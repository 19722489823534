var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { useEffect, useState } from 'react';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { setPayMailingModal } from '@/entity/ModalsEntity/modals.slice';
import { buyMailing, getMailingPrices } from '@/entity/PaymentEntity/payment.slice';
import { Text, Space, Dialog, Button, Input } from '@/shared/ui';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { useDebounce } from '@/shared/hooks/useDebounce/useDebounce';
import { usePromocodeActive } from '@/shared/hooks/usePromocodeActive/usePromocodeActive';
import { PromocodeValidateEnum } from '@/entity/PromocodesEntity/types';
import { preparePriceAfterPromo } from '@/features/utils/preparePriceAfterPromo';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { useHistory } from 'react-router';
import { fakeMailingIdAfterPayment } from '@/types/fakeMailingIdAfterPayment';
import { PayCurrentGraph } from '@/features/ui/Dialogs/PayMailing/PayCurrentGraph';
import { RateBlock } from './RateBlock';
import { SendRequest } from '@/features/ui/Dialogs/PayMailing/SendRequest/SendRequest';
var PresetMailingsEnum;
(function (PresetMailingsEnum) {
    PresetMailingsEnum["OneThousand"] = "1000";
    PresetMailingsEnum["FiveThousand"] = "5000";
    PresetMailingsEnum["TenThousand"] = "10000";
    PresetMailingsEnum["TwentyThousand"] = "20000";
})(PresetMailingsEnum || (PresetMailingsEnum = {}));
const PayMailing = ({ open, isDesktop }) => {
    const query = useQuery();
    const history = useHistory();
    const mailingNeedCount = query.get('mailingNeedMessage');
    const priceMailingId = query.get('priceMailingId');
    const dispatch = useTypedDispatch();
    const mailingPricesState = useTypedSelector((state) => state.paymentReducer.mailingPrices);
    const isValidatePromoLoading = useTypedSelector((state) => state.paymentReducer.promocodeStatus.isLoading);
    const [currentPrice, setCurrentPrice] = useState({ price: 0.2, oldPrice: 0 });
    const [validateValue, setValidateValue] = useState(50);
    const [valueSlider, setValueSlider] = useState([50]);
    const [mailingPrices, setMailingPrices] = useState([]);
    const [nextState, setNextState] = useState({
        nextPrice: 0.17,
        moreMailing: 151,
        nextOldPrice: 0,
    });
    const [withBonusMailings, setWithBonusMailings] = useState({
        freeMailings: 0,
        freeMailingsFrom: 0,
    });
    const [presetMailings, setPresetMailings] = useState(null);
    const [sendRequest, setSendRequest] = useState(false);
    const handleClose = () => {
        sendAmplitudeData('buy_messages_modal_view_closed_btn_clicked');
        dispatch(setPayMailingModal(false));
    };
    const [promoCode, setPromoCode] = useState('');
    const { handleFail, handleSuccess, setHavePromoCode, handleCancelPromocode, havePromocode, handleValidatePromocode, } = usePromocodeActive(promoCode, PromocodeValidateEnum.mailings);
    const debounceValue = useDebounce(valueSlider[0], 1500);
    const getCurrentValueForMailing = (value, updated) => {
        if (!!(updated === null || updated === void 0 ? void 0 : updated.length)) {
            updated === null || updated === void 0 ? void 0 : updated.forEach((el) => {
                if (Number(value) >= Number(el.min) && Number(value) <= Number(el.max)) {
                    setCurrentPrice({ price: el.price, oldPrice: el === null || el === void 0 ? void 0 : el.oldPrice });
                }
            });
        }
        else {
            mailingPrices.forEach((el) => {
                if (Number(value) >= Number(el.min) && Number(value) <= Number(el.max)) {
                    setCurrentPrice({ price: el.price, oldPrice: el === null || el === void 0 ? void 0 : el.oldPrice });
                }
            });
        }
    };
    const handleMoreMail = (value, updatedMailingPrices) => {
        var _a, _b, _c, _d, _e, _f, _g;
        let moreMailing = 0;
        let nextPrice = 0;
        let nextOldPrice = 0;
        for (let i = 0; i < updatedMailingPrices.length; i++) {
            if (Number(updatedMailingPrices[i].max) >= value) {
                if ((_a = updatedMailingPrices[i + 1]) === null || _a === void 0 ? void 0 : _a.price) {
                    nextPrice = (_b = updatedMailingPrices[i + 1]) === null || _b === void 0 ? void 0 : _b.price;
                    if ((_c = updatedMailingPrices[i + 1]) === null || _c === void 0 ? void 0 : _c.oldPrice) {
                        // @ts-ignore
                        nextOldPrice = (_d = updatedMailingPrices[i + 1]) === null || _d === void 0 ? void 0 : _d.oldPrice;
                    }
                    moreMailing = Number(updatedMailingPrices[i + 1].min) - value;
                    break;
                }
                else {
                    if (nextState.nextPrice >= ((_e = updatedMailingPrices[i]) === null || _e === void 0 ? void 0 : _e.price)) {
                        return setNextState({
                            moreMailing: 0,
                            nextPrice: (_f = updatedMailingPrices[i]) === null || _f === void 0 ? void 0 : _f.price,
                            nextOldPrice: 0,
                        });
                    }
                    nextPrice = (_g = updatedMailingPrices[i]) === null || _g === void 0 ? void 0 : _g.price;
                    moreMailing = Number(updatedMailingPrices[i].min) - value;
                    break;
                }
            }
        }
        setNextState({ moreMailing, nextPrice, nextOldPrice });
    };
    const handleCancel = () => {
        handleCancelPromocode();
        setMailingPrices(mailingPricesState);
        handleMoreMail(valueSlider[0], mailingPricesState);
        setWithBonusMailings({ freeMailings: 0, freeMailingsFrom: 0 });
        getCurrentValueForMailing(valueSlider[0], mailingPricesState);
    };
    const handleActivatePromocode = () => __awaiter(void 0, void 0, void 0, function* () {
        const isValid = yield handleValidatePromocode();
        sendAmplitudeData('buy_messages_modal_view_apply_promocode_btn_clicked', {
            promocode: promoCode,
        });
        if (!isValid.payload.code) {
            const { payload } = yield dispatch(getMailingPrices({ promocode: promoCode }));
            if (payload === null || payload === void 0 ? void 0 : payload.freeMailings) {
                setWithBonusMailings({ freeMailings: payload.freeMailings, freeMailingsFrom: payload.freeMailingsFrom });
                setMailingPrices(payload === null || payload === void 0 ? void 0 : payload.mailingPrices);
                handleMoreMail(valueSlider[0], payload === null || payload === void 0 ? void 0 : payload.mailingPrices);
                getCurrentValueForMailing(valueSlider[0], payload === null || payload === void 0 ? void 0 : payload.mailingPrices);
                return;
            }
            const updatedTariffs = preparePriceAfterPromo(payload === null || payload === void 0 ? void 0 : payload.mailingPrices, payload === null || payload === void 0 ? void 0 : payload.mailingPricesPromocode);
            setMailingPrices(updatedTariffs);
            setWithBonusMailings({ freeMailings: 0, freeMailingsFrom: 0 });
            handleMoreMail(valueSlider[0], updatedTariffs);
            getCurrentValueForMailing(valueSlider[0], updatedTariffs);
        }
        else {
            setMailingPrices(mailingPricesState);
            handleMoreMail(valueSlider[0], mailingPricesState);
            setWithBonusMailings({ freeMailings: 0, freeMailingsFrom: 0 });
            getCurrentValueForMailing(valueSlider[0], mailingPricesState);
            return;
        }
    });
    const handleBuy = () => {
        let domain = window.location.origin;
        if (priceMailingId) {
            domain = `${domain}/newMailing?mailingId=${priceMailingId}&mailingIdAfterPayment=${priceMailingId}&`;
        }
        else {
            domain = `${domain}/homeMailings?mailingIdAfterPayment=${fakeMailingIdAfterPayment}&`;
        }
        sendAmplitudeData('buy_messages_modal_view_buy_btn_clicked', {
            count: valueSlider[0],
        });
        if (handleSuccess) {
            dispatch(buyMailing({ numberMailings: valueSlider[0], domain, promocode: promoCode }));
        }
        else {
            dispatch(buyMailing({ numberMailings: valueSlider[0], domain }));
        }
    };
    const handleChangeSlider = (number) => {
        getCurrentValueForMailing(number[0]);
        setValueSlider(number);
        setValidateValue(number[0]);
        handleMoreMail(number[0], mailingPrices);
    };
    const handleChangeInput = (event) => {
        getCurrentValueForMailing(event.target.value);
        setValidateValue(event.target.value);
        if (presetMailings) {
            setPresetMailings(null);
        }
        // if (Number(event.target.value) >= 50000) {
        //   setValueSlider([50000]);
        //   handleMoreMail(50000, mailingPrices);
        //   return;
        // }
        if (mailingNeedCount) {
            if (+mailingNeedCount >= Number(event.target.value)) {
                setValueSlider([+mailingNeedCount]);
                handleMoreMail(+mailingNeedCount, mailingPrices);
                return;
            }
        }
        else {
            if (Number(event.target.value) <= 50) {
                setValueSlider([50]);
                handleMoreMail(50, mailingPrices);
                return;
            }
        }
        if (Number(event.target.value)) {
            setValueSlider([Number(event.target.value)]);
            handleMoreMail(Number(event.target.value), mailingPrices);
        }
    };
    const handleHavePromocode = (value) => {
        sendAmplitudeData('buy_messages_modal_view_i_have_promocode_btn_clicked');
        setHavePromoCode(value);
    };
    useEffect(() => {
        if (mailingPricesState.length !== 0) {
            setMailingPrices(mailingPricesState);
        }
    }, [mailingPricesState]);
    useEffect(() => {
        if (mailingPricesState.length === 0) {
            dispatch(getMailingPrices({}));
        }
    }, []);
    useEffect(() => {
        sendAmplitudeData('buy_messages_modal_view_count_changed', {
            count: valueSlider[0],
        });
    }, [debounceValue]);
    useEffect(() => {
        if (mailingNeedCount && mailingPricesState.length !== 0) {
            if (+mailingNeedCount >= 50) {
                setValueSlider([+mailingNeedCount]);
                setValidateValue(+mailingNeedCount);
                handleMoreMail(+mailingNeedCount, mailingPrices);
                getCurrentValueForMailing(+mailingNeedCount);
            }
        }
    }, [mailingPrices]);
    useEffect(() => {
        return () => {
            query.delete('mailingNeedMessage');
            query.delete('priceMailingId');
            history.push({ search: query.toString() });
        };
    }, []);
    return (_jsxs(Dialog, { open: open, closeIcon: true, onOpenChange: handleClose, css: {
            maxWidth: '610px',
            width: '610px',
            top: isDesktop ? '49%' : '50%',
        }, children: [_jsx(SendRequest, { setSendRequest: setSendRequest, currentPrice: currentPrice, withBonusMailings: withBonusMailings, valueSlider: valueSlider, sendRequest: sendRequest, havePromocode: havePromocode, handleCancel: handleCancel, handleHavePromocode: handleHavePromocode, handleFail: handleFail, handleSuccess: handleSuccess, handleActivatePromocode: handleActivatePromocode, setPromoCode: setPromoCode, promoCode: promoCode }), !sendRequest &&
                _jsxs(Space, { direction: "vertical", children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u041F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0431\u0430\u043B\u0430\u043D\u0441" }), _jsxs(S.PresetContainer, { children: [_jsxs(Space, { size: "default", children: [_jsx(Button, { onClick: () => {
                                                handleChangeSlider([+PresetMailingsEnum.OneThousand]);
                                                setPresetMailings(PresetMailingsEnum.OneThousand);
                                            }, type: presetMailings === PresetMailingsEnum.OneThousand ? 'activePresetMailingPay' : 'presetMailingPay', children: "1000" }), _jsx(Button, { onClick: () => {
                                                handleChangeSlider([+PresetMailingsEnum.FiveThousand]);
                                                setPresetMailings(PresetMailingsEnum.FiveThousand);
                                            }, type: presetMailings === PresetMailingsEnum.FiveThousand ? 'activePresetMailingPay' : 'presetMailingPay', children: "5000" }), _jsx(Button, { onClick: () => {
                                                handleChangeSlider([+PresetMailingsEnum.TenThousand]);
                                                setPresetMailings(PresetMailingsEnum.TenThousand);
                                            }, type: presetMailings === PresetMailingsEnum.TenThousand ? 'activePresetMailingPay' : 'presetMailingPay', children: "10 000" }), _jsx(Button, { onClick: () => {
                                                handleChangeSlider([+PresetMailingsEnum.TwentyThousand]);
                                                setPresetMailings(PresetMailingsEnum.TwentyThousand);
                                            }, type: presetMailings === PresetMailingsEnum.TwentyThousand ? 'activePresetMailingPay' : 'presetMailingPay', children: "20 000" })] }), _jsxs(Space, { align: "center", children: [_jsx(Text, { type: "default", children: "\u041A\u0443\u043F\u0438\u0442\u044C \u0435\u0449\u0435" }), _jsx(S.MailingInput, { onChange: handleChangeInput, value: validateValue }), _jsx(Text, { type: "default", children: "\u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439" })] }), _jsxs(S.GraphContainer, { children: [_jsx(PayCurrentGraph, { changeSlider: handleChangeSlider, mailingPrices: mailingPrices, currentPrice: currentPrice }), _jsxs(Space, { children: [_jsxs(S.RateMessage, { children: [_jsxs(Text, { css: {
                                                                color: '$textBlackColor',
                                                                marginBottom: '4px',
                                                                fontWeight: 400,
                                                                lineHeight: '12px',
                                                                fontSize: '13px',
                                                            }, type: "labelText", children: ["\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043E\u0434\u043D\u043E\u0433\u043E \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F ", _jsx("br", {}), " \u0441\u0435\u0439\u0447\u0430\u0441"] }), _jsx(Space, { size: "default", align: "flexEnd", children: _jsxs(Text, { css: { color: '#414BB2', fontSize: '17px' }, ellipse: true, type: "default", children: [_jsx("strong", { children: currentPrice.price }), " \u20BD/\u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435"] }) })] }), currentPrice.price !== nextState.nextPrice && _jsxs(S.RateMessage, { children: [_jsxs(Text, { css: { color: '$textBlackColor', marginBottom: '4px', fontWeight: 400, lineHeight: '12px' }, type: "labelText", children: ["\u0412\u043E\u0437\u044C\u043C\u0438\u0442\u0435 \u0435\u0449\u0435 ", _jsx("strong", { style: { color: '#3BB44A' }, children: nextState.moreMailing }), " \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439,", _jsx("br", {}), "\u0447\u0442\u043E\u0431\u044B \u0441\u043D\u0438\u0437\u0438\u0442\u044C \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u0434\u043E"] }), _jsx(Space, { children: _jsx(Text, { css: { color: '$acceptButton', fontSize: '17px' }, type: "default", ellipse: true, children: _jsxs("div", { style: { display: 'flex' }, children: [_jsx("strong", { style: { marginRight: '4px' }, children: nextState.nextPrice }), !!(nextState === null || nextState === void 0 ? void 0 : nextState.nextOldPrice) &&
                                                                            _jsx(Text, { css: {
                                                                                    color: '$acceptButton',
                                                                                    textDecoration: 'line-through',
                                                                                    fontSize: '13px',
                                                                                    fontWeight: 300,
                                                                                    lineHeight: '18px',
                                                                                    marginRight: '4px',
                                                                                }, children: nextState === null || nextState === void 0 ? void 0 : nextState.nextOldPrice }), "\u20BD/\u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435"] }) }) })] })] })] })] }), _jsx("div", { style: { marginBottom: '16px' }, children: _jsx(RateBlock, { currentPrice: currentPrice, withBonusMailings: withBonusMailings, valueSlider: valueSlider }) }), _jsxs(Space, { css: { marginBottom: '16px' }, content: "between", align: "center", children: [_jsx(Text, { onClick: () => {
                                        handleHavePromocode(!havePromocode);
                                        if (havePromocode) {
                                            handleCancel();
                                        }
                                    }, type: 'select', children: havePromocode ? 'Отменить промокод' : 'У меня есть промокод' }), _jsx(Text, { onClick: () => setSendRequest(true), type: 'select', children: "\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C \u0441\u0447\u0435\u0442" })] }), havePromocode &&
                            _jsxs(Space, { css: { marginBottom: '24px' }, direction: "vertical", size: "default", children: [_jsxs(Space, { align: "center", size: "default", children: [_jsx(Text, { children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }), _jsx(Input, { value: promoCode, onChange: (event) => setPromoCode(event.target.value), css: { height: '34px' } }), _jsx("div", { style: { minWidth: '140px' }, children: _jsx(Button, { onClick: handleActivatePromocode, type: "default", children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u043A\u043E\u0434" }) })] }), handleSuccess &&
                                        _jsx(Text, { algin: "center", css: { color: '$acceptButton' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D" }), handleFail &&
                                        _jsx(Text, { algin: "center", css: { color: '$error' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D" })] }), _jsx(Button, { loading: isValidatePromoLoading, css: { height: '43px' }, onClick: handleBuy, type: "payment", children: "\u041F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C" })] })] }));
};
export { PayMailing };
