import { styled } from '@/app/styles/mainStyles';
export const Button = styled('button', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px',
    borderRadius: '28px',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    border: 'none',
    justifyContent: 'center',
    outline: 'none',
    fontFamily: '$nunito',
    variants: {
        type: {
            primary: {
                backgroundColor: '$activeBackground',
                color: '$white',
                '&:hover': {
                    background: '$active',
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            stats: {
                borderRadius: '8px',
                color: '$activeBackground',
                border: '1px solid $border',
                background: '$deliveryBackground',
                '&:hover': {
                    color: '$active',
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            presetMailingPay: {
                borderRadius: '4px',
                color: '$active',
                fontWeight: '700',
                padding: '4px 8px',
                border: '1px solid $border',
                background: '$deliveryBackground',
                '&:hover': {
                    color: '$active',
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            activePresetMailingPay: {
                borderRadius: '4px',
                color: '$white',
                fontWeight: '700',
                padding: '4px 8px',
                background: '$activeBackground',
                border: '1px solid $activeBackground',
                '&:hover': {
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            sortDisable: {
                padding: '4px 12px',
                border: '1px dashed $active',
                background: '$white',
                color: 'rgba(65, 75, 178, 1)',
                borderRadius: '8px',
                fontSize: '14px',
            },
            sortActive: {
                padding: '4px 12px',
                border: '1px solid $active',
                borderRadius: '8px',
                background: 'rgba(84, 95, 201, 0.2)',
                color: '$active',
                fontSize: '14px',
            },
            delete: {
                borderRadius: '8px',
                color: '$white',
                border: 'none',
                background: '$error',
                '&:hover': {
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            logout: {
                borderRadius: '8px',
                color: '$error',
                border: '1px solid $error',
                background: '$revenueFaultBg',
                '&:hover': {
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            pagination: {
                width: '21px',
                height: '21px',
                borderRadius: '50%',
                background: 'none',
                padding: '4px 6px',
                '&:hover': {
                    opacity: 0.8,
                },
            },
            payment: {
                background: '$acceptButton',
                color: '$white',
                '&:hover': {
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            default: {
                background: '$deliveryBackground',
                color: '$activeBackground',
                border: '1px solid $border',
                '&:hover': {
                    background: '#F3F7FB',
                    color: '#545FC9',
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            danger: {
                border: '1px solid $revenueFault',
                background: '$revenueFaultBg',
                color: '$error',
                '&:hover': {
                    background: '$revenueFaultBg',
                    color: '$textError',
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            closeSideBar: {
                padding: '10px 13px',
                '&:hover': {
                    background: '$deliveryBackground',
                    color: '$textError',
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            disable: {
                background: 'rgb(237, 240, 244)',
                color: 'rgb(164, 169, 181)',
                '&:hover': {
                    cursor: 'not-allowed',
                    boxShadow: 'none',
                },
            },
            filtered: {
                background: '$white',
                color: '$activeBackground',
                textDecoration: 'underline',
                colorDecoration: '$activeBackground',
                '&:hover': {
                    color: '$active',
                    colorDecoration: '$active',
                },
            },
        },
        withoutBorder: {
            true: {
                border: 'none',
            },
        },
        stretch: {
            true: {
                width: '100%',
            },
        },
        activeFiltered: {
            true: {
                color: '$active',
                border: '1px solid $active',
                background: '$backgroundFilteredButton',
            },
        },
        activePagination: {
            true: {
                width: '21px',
                height: '21px',
                borderRadius: '50%',
                background: '$paginationButton',
                padding: '4px 6px',
                color: '$activeBackground',
            },
        },
        disabled: {
            true: {
                background: 'rgba(164, 169, 181, 1)',
                color: 'rgba(255, 255, 255, 1)',
                border: '1px solid rgba(164, 169, 181, 1)',
                '&:hover': {
                    background: 'rgba(164, 169, 181, 1)',
                    color: 'rgba(255, 255, 255, 1)',
                    boxShadow: 'none',
                    cursor: 'not-allowed',
                },
                '> svg': {
                    fill: 'rgba(164, 169, 181, 1)',
                },
                '> span': {
                    '> svg': {
                        fill: 'rgba(164, 169, 181, 1)',
                    },
                },
            },
        },
    },
});
