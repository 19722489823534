var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReqPayment, postReqPayment, putReqPayment, removeReqPayment } from '@/shared/api/payment';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { displayError } from '@/shared/utils/displayError/displayError';
import { postMailing } from '@/shared/api/mailing';
const initialState = {
    promocodesList: {
        promocodes: [],
        total: 0,
    },
    invitecodesList: {
        codes: [],
        total: 0,
    },
    currentInvitecode: null,
    currentPromocode: null,
    promocodesStatus: BASE_FETCH_STATUS,
    invitecodesStatus: BASE_FETCH_STATUS,
};
const getListPromocodes = createAsyncThunk('promocodesSlice/getListPromocodes', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`promocode/list_aggregate?limit=${payload.limit}&offset=${payload.offset}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getListInveitecodes = createAsyncThunk('promocodesSlice/getListInveitecodes', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`invite_codes?limit=${payload.limit}&offset=${payload.offset}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removeInveitecode = createAsyncThunk('promocodesSlice/removeInveitecode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield removeReqPayment(`invite_code?id=${payload}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removePromocode = createAsyncThunk('promocodesSlice/removePromocode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield removeReqPayment(`promocode/delete?id=${payload}`);
        return {
            id: payload,
        };
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getInveitecode = createAsyncThunk('promocodesSlice/getInveitecode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`invite_code?id=${payload}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const createInvitecode = createAsyncThunk('promocodesSlice/createInvitecode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield postReqPayment('invite_code', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const setMailingBalanceForUser = createAsyncThunk('promocodesSlice/setMailingBalanceForUser', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield postMailing('balance', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getCurrentPromocode = createAsyncThunk('promocodesSlice/getCurrentPromocode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`promocode/get_aggregate?id=${payload.id}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const createPromocode = createAsyncThunk('promocodesSlice/createPromocode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield postReqPayment('promocode/save', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const validatePromocode = createAsyncThunk('promocodesSlice/validatePromocode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`promocode/validate?name=${payload.name}&productType=${payload.type}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            return err.response.data;
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const updatePromocode = createAsyncThunk('promocodesSlice/updatePromocode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield putReqPayment('promocode/update', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const promocodesSlice = createSlice({
    name: 'promocodesSlice',
    initialState,
    reducers: {
        clearCurrentPromocode: (state) => {
            state.currentPromocode = null;
        },
        clearCurrentInvitecode: (state) => {
            state.currentInvitecode = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getListPromocodes.pending, (state) => {
            state.promocodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(getListPromocodes.rejected, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesStatus.error = payload;
        });
        builder.addCase(getListPromocodes.fulfilled, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesList = payload;
        });
        builder.addCase(getListInveitecodes.pending, (state) => {
            state.invitecodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(getListInveitecodes.rejected, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
            state.invitecodesStatus.error = payload;
        });
        builder.addCase(getListInveitecodes.fulfilled, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
            state.invitecodesList = payload;
        });
        builder.addCase(createInvitecode.pending, (state) => {
            state.invitecodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(createInvitecode.rejected, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
            state.invitecodesStatus.error = payload;
        });
        builder.addCase(createInvitecode.fulfilled, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
        });
        builder.addCase(removePromocode.pending, (state) => {
            state.promocodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(removePromocode.rejected, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesStatus.error = payload;
        });
        builder.addCase(removePromocode.fulfilled, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesList.promocodes = state.promocodesList.promocodes.filter((promocode) => promocode.id !== payload.id);
            state.promocodesList.total--;
        });
        builder.addCase(removeInveitecode.pending, (state) => {
            state.invitecodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(removeInveitecode.rejected, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
            state.invitecodesStatus.error = payload;
        });
        builder.addCase(removeInveitecode.fulfilled, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
            state.invitecodesList.codes = state.invitecodesList.codes.filter((promocode) => promocode.id !== payload.id);
            state.invitecodesList.total--;
        });
        builder.addCase(getCurrentPromocode.pending, (state) => {
            state.promocodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(getCurrentPromocode.rejected, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesStatus.error = payload;
        });
        builder.addCase(getCurrentPromocode.fulfilled, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.currentPromocode = payload.promocode;
        });
        builder.addCase(getInveitecode.pending, (state) => {
            state.invitecodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(getInveitecode.rejected, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
            state.invitecodesStatus.error = payload;
        });
        builder.addCase(getInveitecode.fulfilled, (state, { payload }) => {
            state.invitecodesStatus.isLoading = false;
            state.currentInvitecode = payload.code;
        });
        builder.addCase(updatePromocode.pending, (state) => {
            state.promocodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(updatePromocode.rejected, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesStatus.error = payload;
        });
        builder.addCase(updatePromocode.fulfilled, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesList.promocodes = state.promocodesList.promocodes.map((promocode) => {
                if (promocode.id === payload.promocode.id) {
                    return payload.promocode;
                }
                return promocode;
            });
        });
        builder.addCase(validatePromocode.pending, (state) => {
            state.promocodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(validatePromocode.fulfilled, (state, { payload }) => {
        });
        builder.addCase(createPromocode.pending, (state) => {
            state.promocodesStatus = START_FETCH_STATUS;
        });
        builder.addCase(createPromocode.rejected, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesStatus.error = payload;
        });
        builder.addCase(createPromocode.fulfilled, (state, { payload }) => {
            state.promocodesStatus.isLoading = false;
            state.promocodesList.promocodes = [...state.promocodesList.promocodes, payload.aggregatePromocode];
            state.promocodesList.total += 1;
        });
    },
});
export default promocodesSlice.reducer;
export const { clearCurrentPromocode, clearCurrentInvitecode } = promocodesSlice.actions;
export { getListPromocodes, getListInveitecodes, createInvitecode, getInveitecode, removePromocode, removeInveitecode, getCurrentPromocode, setMailingBalanceForUser, validatePromocode, updatePromocode, createPromocode, };
