import Cookies from 'universal-cookie';
const cookies = new Cookies();
export const interceptorConfig = (config) => {
    const token = cookies.get('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};
export const interceptorResponseConfig = (error) => {
    var _a;
    try {
        if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && error.config) {
            cookies.remove('token');
            window.location.reload();
        }
    }
    catch (e) {
        console.log('e', e);
    }
    throw error;
};
