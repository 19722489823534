var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DatePicker, Dialog, Input, Select, Space, Text, TextArea } from '@/shared/ui';
import React, { useEffect, useState } from 'react';
import { CalculateBlock } from '@/widgets/ui/Promocodes/ui/CalculateBlock/CalculateBlock';
import { MailingBlock } from '@/widgets/ui/Promocodes/ui/MailingBlock/MailingBlock';
import { PromocodesActionTypesEnum, PromocodesApplyTypesLocaleEnum, } from '@/features/ui/Dialogs/PromocodesSettingsDialog/types';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { clearCurrentPromocode, createPromocode, } from '@/entity/PromocodesEntity/promocodes.slice';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { getMailingPrices } from '@/entity/PaymentEntity/payment.slice';
import { v4 as uuidv4 } from 'uuid';
const selectPromocodeType = [
    {
        name: 'Без дополнительных условий',
        id: PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_FOR_ALL,
        value: PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_FOR_ALL,
    },
    {
        name: 'Для определенных пользователей',
        id: PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_ON_IDS,
        value: PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_ON_IDS,
    },
    {
        name: 'С ограничением на количество активаций',
        id: PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_REPEATEDLY,
        value: PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_REPEATEDLY,
    },
];
const PromocodesSettingsDialog = ({ setOpen }) => {
    const dispatch = useTypedDispatch();
    const mailingTariffs = useTypedSelector(state => state.paymentReducer.mailingPrices);
    const currentPromocode = useTypedSelector(state => state.promocodesReducer.currentPromocode);
    const [dateRange, setDateRange] = useState([null, null]);
    const [activeBlocks, setActiveBlock] = useState({
        calculate: false,
        mailing: false,
    });
    const [type, setType] = useState(null);
    const [activateNow, setActivateNow] = useState(false);
    const [idsValues, setIdsValues] = useState('');
    const [selectDate, setSelectDate] = useState(null);
    const [promocode, setPromocode] = useState({
        name: '',
        productRuleCalculator: {
            tariffIds: [],
            percent: 0,
        },
        productRuleMailing: {
            prices: [],
            freeMailings: '',
            freeMailingsFrom: '',
        },
        promocodeApplyType: PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_FOR_ALL,
        activatedAt: new Date().toISOString(),
        expiredAt: '',
        userIds: [],
        count: 0,
        activatedAtNow: false,
    });
    const prepareBeforeSave = () => {
        if (type === 'gift' && activeBlocks.mailing) {
            return { productRuleMailing: Object.assign(Object.assign({}, promocode.productRuleMailing), { prices: [...mailingTariffs] }) };
        }
        if (type === 'range' && activeBlocks.mailing) {
            return (promocode.productRuleMailing.prices.filter((item) => +item.price !== +item.oldPrice).length !== 0) ? {
                productRuleMailing: Object.assign(Object.assign({}, promocode.productRuleMailing), { freeMailings: 0, freeMailingsFrom: 0 }),
            } : null;
        }
    };
    const handleCreatePromocode = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const updatedData = Object.assign(Object.assign({}, promocode), { productRuleCalculator: (activeBlocks.calculate && promocode.productRuleCalculator.tariffIds.length !== 0) ? promocode.productRuleCalculator : null, productRuleMailing: (_a = prepareBeforeSave()) === null || _a === void 0 ? void 0 : _a.productRuleMailing, userIds: promocode.promocodeApplyType === PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_ON_IDS ? idsValues.split(', ').map((item) => item.trim()) : [], count: promocode.promocodeApplyType === PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_REPEATEDLY ? +promocode.count : 0, activatedAtNow: !activateNow });
        const data = yield dispatch(createPromocode(updatedData));
        if (!data.payload.code) {
            setOpen(false);
        }
    });
    const handleClose = () => {
        dispatch(clearCurrentPromocode());
        setOpen(false);
    };
    const handleChangeType = (value) => {
        setPromocode(Object.assign(Object.assign({}, promocode), { promocodeApplyType: value }));
    };
    const handdleChangeName = (event) => {
        setPromocode(Object.assign(Object.assign({}, promocode), { name: event.target.value }));
    };
    const handleChangeDateRange = (dates) => {
        if (!(dates === null || dates === void 0 ? void 0 : dates.length)) {
            setSelectDate(dates);
            const expiredAtDate = new Date(dates);
            expiredAtDate.setHours(23, 59, 59, 999);
            setPromocode(Object.assign(Object.assign({}, promocode), { expiredAt: expiredAtDate.toISOString() }));
            return;
        }
        const [start, end] = dates;
        const expiredAtDate = new Date(end);
        expiredAtDate.setHours(23, 59, 59, 999);
        setPromocode(Object.assign(Object.assign({}, promocode), { activatedAt: start === null || start === void 0 ? void 0 : start.toISOString(), expiredAt: expiredAtDate === null || expiredAtDate === void 0 ? void 0 : expiredAtDate.toISOString() }));
        setDateRange(dates);
    };
    const handlePrepareMailingTariffs = () => {
        var _a, _b, _c;
        if (mailingTariffs.length !== 0 && !currentPromocode) {
            const preparedMailingTariffs = mailingTariffs.map((tariff) => {
                return Object.assign(Object.assign({}, tariff), { id: uuidv4(), oldPrice: tariff.price });
            });
            return preparedMailingTariffs;
        }
        else if (mailingTariffs.length !== 0 && ((_b = (_a = currentPromocode === null || currentPromocode === void 0 ? void 0 : currentPromocode.productRuleMailing) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b.length) !== 0) {
            const preparedMailingTariffs = [];
            for (let i = 0; i < mailingTariffs.length; i++) {
                const item = Object.assign(Object.assign({}, mailingTariffs[i]), { id: uuidv4(), oldPrice: mailingTariffs[i].price, price: ((_c = currentPromocode === null || currentPromocode === void 0 ? void 0 : currentPromocode.productRuleMailing) === null || _c === void 0 ? void 0 : _c.prices[i].price) || mailingTariffs[i].price });
                preparedMailingTariffs.push(item);
            }
            return preparedMailingTariffs;
        }
    };
    useEffect(() => {
        if (mailingTariffs.length === 0) {
            dispatch(getMailingPrices({}));
        }
    }, []);
    const handleSetValuesForUpdate = (currentPromocodeValue) => {
        var _a, _b;
        if (currentPromocodeValue.productRuleCalculator) {
            setActiveBlock((prev) => (Object.assign(Object.assign({}, prev), { calculate: true })));
        }
        if (currentPromocodeValue.productRuleMailing) {
            setActiveBlock((prev) => (Object.assign(Object.assign({}, prev), { mailing: true })));
        }
        if (!!((_a = currentPromocodeValue.productRuleMailing) === null || _a === void 0 ? void 0 : _a.freeMailings) || !!((_b = currentPromocodeValue.productRuleMailing) === null || _b === void 0 ? void 0 : _b.freeMailingsFrom)) {
            setType('gift');
        }
        else if (!!currentPromocodeValue.productRuleMailing) {
            setType('range');
        }
        if (currentPromocodeValue.activatedAt) {
            setActivateNow(!!currentPromocodeValue.activatedAt);
        }
        else {
            setActivateNow(undefined);
        }
    };
    useEffect(() => {
        if (currentPromocode) {
            const updatedMailingTariffs = handlePrepareMailingTariffs();
            const ruleCalculator = currentPromocode.productRuleCalculator ? Object.assign({}, currentPromocode.productRuleCalculator) : Object.assign({}, promocode.productRuleCalculator);
            setPromocode(Object.assign(Object.assign({}, currentPromocode), { productRuleMailing: Object.assign(Object.assign({}, currentPromocode.productRuleMailing), { prices: updatedMailingTariffs }), productRuleCalculator: ruleCalculator }));
            setDateRange([new Date(currentPromocode.activatedAt), new Date(currentPromocode.expiredAt)]);
            handleSetValuesForUpdate(currentPromocode);
        }
    }, [currentPromocode, mailingTariffs]);
    useEffect(() => {
        if (mailingTariffs.length !== 0) {
            const updatedMailingTariffs = handlePrepareMailingTariffs();
            setPromocode(Object.assign(Object.assign({}, promocode), { productRuleMailing: { prices: updatedMailingTariffs } }));
        }
    }, [mailingTariffs]);
    return (_jsx(Dialog, { open: true, closeIcon: true, outside: false, onOpenChange: handleClose, css: {
            background: '$white',
            minWidth: '610px',
            height: '90vh',
        }, children: _jsxs(Space, { size: "default", direction: "vertical", children: [_jsx(Text, { type: "title", children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }), _jsxs(Space, { size: "default", direction: "vertical", children: [_jsx(Input, { css: { height: '34px' }, onChange: handdleChangeName, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", typeInput: "default", value: promocode.name }), _jsx(Space, { css: { width: '65%' }, children: _jsx(DatePicker, { selected: !activateNow ? selectDate : null, checked: activateNow, setChecked: () => setActivateNow(!activateNow), minDateValue: new Date(), maxDate: new Date('2030-01-01'), type: "promocode", canFuture: true, startDate: activateNow ? dateRange[0] : null, endDate: activateNow ? dateRange[1] : null, onChange: handleChangeDateRange }) })] }), _jsx(CalculateBlock, { promocode: promocode, setPromocode: setPromocode, activeBlocks: activeBlocks, setActiveBlocks: setActiveBlock }), _jsx(MailingBlock, { type: type, setType: setType, promocode: promocode, setPromocode: setPromocode, activeBlocks: activeBlocks, setActiveBlocks: setActiveBlock }), _jsxs(Space, { align: "center", size: "default", css: { margin: '24px 0' }, children: [_jsx(Text, { type: "default", children: "\u0414\u043E\u043F \u0443\u0441\u043B\u043E\u0432\u0438\u0435" }), _jsx(Select, { css: { width: '60%', marginBottom: 0 }, onValueChange: (value) => handleChangeType(value), value: PromocodesApplyTypesLocaleEnum[promocode.promocodeApplyType], defaultValue: "\u0422\u0438\u043F \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434\u0430", children: selectPromocodeType.map((option) => (_jsx(Select.Item, { value: option.value, children: option.name }, option.id))) })] }), (promocode === null || promocode === void 0 ? void 0 : promocode.promocodeApplyType) === PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_REPEATEDLY &&
                    _jsxs(Space, { content: "center", direction: "vertical", size: "default", css: { marginBottom: '34px' }, children: [_jsx(Text, { css: { fontWeight: 700 }, type: "default", children: "\u041A\u043E\u043B\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0439" }), _jsx(Input, { onChange: (event) => setPromocode(Object.assign(Object.assign({}, promocode), { count: event.target.value })), css: { height: '34px', marginBottom: 0 }, typeInput: "default", value: promocode.count })] }), (promocode === null || promocode === void 0 ? void 0 : promocode.promocodeApplyType) === PromocodesActionTypesEnum.PROMOCODE_APPLY_TYPE_ON_IDS &&
                    _jsxs(Space, { content: "center", direction: "vertical", size: "default", css: { marginBottom: '34px' }, children: [_jsxs(Space, { align: "center", size: "default", children: [_jsx(Text, { type: "default", children: "UserID" }), _jsx(Text, { type: "labelText", children: "\u0424\u043E\u0440\u043C\u0430\u0442 \u0432\u0432\u043E\u0434\u0430: \", \"" })] }), _jsx(TextArea, { css: { height: '20vh', marginBottom: 0 }, typeInput: "default", onChange: (event) => setIdsValues(event.target.value), value: idsValues })] }), _jsx(Space, { css: { marginTop: '24px' }, content: "flexEnd", children: _jsx(Button, { onClick: handleCreatePromocode, type: "primary", children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" }) })] }) }));
};
export { PromocodesSettingsDialog };
