import { createStitches, globalCss } from '@stitches/react';
export const { getCssText, styled, css, createTheme } = createStitches({
    theme: {
        colors: {
            white: '#FFF',
            black: '#000',
            border: 'rgb(179, 184, 231)',
            active: '#545FC9',
            gray: '#F7F7F7',
            whiteGray: '#F6F6F6',
            disableBackground: 'rgb(237, 240, 244)',
            disableColor: 'rgb(164, 169, 181)',
            scrollColor: 'rgba(60, 67, 90, 1)',
            activeBackground: '#414BB2',
            revenueGood: '#B3E7C7',
            revenueGoodBg: '#F3FBF5',
            revenueFault: '#E7B3B3',
            revenueFaultBg: '#FBF3F3',
            suspenseLoader: '#EDF0F4',
            messageBg: '#F5F7FA',
            textBlackColor: '#3C435A',
            labelColor: '#A4A9B5',
            modalColor: '#212121',
            error: '#BC3131',
            plannedBorder: '#B3B8E7',
            acceptButton: '#3BB44A',
            activeSwitchGreen: '#57D59C',
            shopWarning: '#D7C63A',
            shopWarningDescription: '#CED12A',
            errorRed: '#A52C25',
            errorLight: '#C14E49',
            textError: '#D14343',
            logisticError: '#E38E8E',
            boxShadowError: '#F14343A5',
            paginationButton: '#DDDFF4',
            deliveryBackground: '#F3F7FB',
            previewMessage: '#D9D9D9',
            boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.2)',
            limitGradient: 'linear-gradient(100.11deg, #F0F1E8 13.03%, #C7DDF2 69.91%, #D6F2F1 100%)',
            backgroundFilteredButton: 'rgba(84, 95, 201, 0.2)',
        },
        fonts: {
            inter: 'Inter, sans-serif',
            nunito: 'Nunito, sans-serif',
            roboto: 'Roboto, sans-serif',
            sf: 'San Francisco, sans-serif',
        },
    },
    media: {
        desktop: 'only screen and (min-width: 1025px)',
        mobile: 'only screen and (max-width: 1025px)',
    },
});
export const MainContainer = styled('main', {
    width: '100%',
    height: 'calc(100vh - 34px)',
    paddingTop: '17px',
    overflowX: 'hidden !important',
    paddingBottom: '17px',
    background: 'rgb(246,246,246)',
    variants: {
        authPage: {
            true: {
                padding: '0',
                height: '100vh',
            },
        },
    },
});
export const Main = styled('main', {
    fontFamily: '$nunito',
    background: 'transparent',
    maxWidth: '1440px',
    margin: '0 auto',
    variants: {
        pageFullWidth: {
            true: {
                maxWidth: '100%',
            },
        },
    },
});
export const SideBarMobileContainer = styled('aside', {
    width: '100%',
    height: '74px',
    backgroundColor: 'transparent',
    zIndex: 1,
    variants: {
        isOpen: {
            true: {
                position: 'fixed',
                left: 0,
                width: '100%',
                top: 0,
                height: '120%',
            },
        },
    },
});
export const SideBarContainer = styled('aside', {
    position: 'fixed',
    minWidth: '280px',
    maxWidth: '280px',
    height: '100vh',
    background: 'transparent',
    variants: {
        isClosed: {
            true: {
                minWidth: '120px',
                maxWidth: '120px',
                height: '64px',
                '& .sidebar__toggle-button': {
                    transform: 'rotate(-90deg)',
                },
                '& .sidebar__label': {
                    transform: 'translateX(25px)',
                    opacity: 0,
                    wordBreak: 'keep-all',
                    whiteSpace: 'nowrap',
                },
                '& .sidebar__icon': {
                    marginRight: 0,
                },
                '& .sidebar__link': {
                    padding: '0 25px',
                },
            },
        },
    },
});
export const MainContentContainer = styled('section', {
    background: '$white',
    overflow: 'auto !important',
    borderRadius: '16px',
    padding: '16px',
    '@desktop': {
        transform: 'translateX(280px)',
        width: 'calc(100% - 280px)',
        height: '96vh',
    },
    '@mobile': {
        width: '100%',
    },
    variants: {
        register: {
            true: {
                // marginLeft: '0px !important',
                transform: 'translateX(0px)',
                width: '100%',
                height: '100vh',
                borderRadius: '0',
                padding: '0',
            },
        },
        isTransition: {
            true: {
                transition: 'transform 0.3s ease-in-out',
            },
        },
        isSidebarOpened: {
            true: {
                // marginLeft: '120px',
                '@desktop': {
                    transform: 'translateX(120px)',
                    width: 'calc(100% - 120px)',
                },
            },
        },
    },
});
const setupStyles = globalCss({
    body: {
        margin: 0,
        padding: 0,
        // flexGrow: 1,
        fontFamily: '$nunito',
    },
    'h1, h2, h3, h4, h5, h6, p': {
        fontFamily: '$inter',
        margin: 0,
    },
    'body, div, section': {
        boxSizing: 'border-box',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'rgba(60,67,90,0.3)',
            borderRadius: '16px',
        },
    },
});
export const setupGlobalStyles = () => {
    setupStyles();
};
