import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import { Space } from '@/shared/ui';
import { useCallback } from 'react';
const PayCurrentGraph = ({ mailingPrices, currentPrice, changeSlider }) => {
    const getType = useCallback((value, currentIndex) => {
        const findIndexElement = mailingPrices.findIndex((el) => el.price === currentPrice.price);
        if (findIndexElement !== -1 && findIndexElement + 1 >= currentIndex && currentIndex > findIndexElement) {
            return 'nextOne';
        }
        if (currentPrice.price === value.price) {
            return 'current';
        }
        if (currentPrice.price > value.price) {
            return 'next';
        }
        if (currentPrice.price < value.price) {
            return 'before';
        }
    }, [mailingPrices, currentPrice]);
    const handleClick = (maxValue) => {
        if (maxValue > 10000) {
            changeSlider([10001]);
            return;
        }
        changeSlider([maxValue]);
    };
    return (_jsx(Space, { css: { gap: '2px', width: '100%' }, children: mailingPrices.map((el, index) => {
            return (_jsxs(S.GraphItemContainer, { onClick: () => handleClick(+el.max), type: getType(el, index), children: [_jsxs(S.TextGraph, { algin: "center", type: getType(el, index), children: [_jsxs("span", { children: [el.price, " \u20BD"] }), "\u00A0", el.oldPrice && _jsxs("span", { style: { textDecoration: 'line-through' }, children: [el.oldPrice, " \u20BD"] })] }), _jsx(S.GraphItem, { type: getType(el, index) }), _jsx(S.TextGraph, { algin: "center", type: getType(el, index), children: index === 5 ? '10000+' : `${el.min} - ${el.max}` })] }, index));
        }) }));
};
export { PayCurrentGraph };
