import * as yup from 'yup';
export const PasswordSchema = yup.object({
    old: yup
        .string()
        .required({ text: 'Обязательное поле' })
        .min(7)
        .trim(),
    new: yup
        .string()
        .min(8, { text: 'Пароль не может быть меньше 8 символов' })
        .trim()
        .required({ text: 'Обязательное поле' }),
    confirm: yup
        .string()
        .oneOf([yup.ref('new')], { text: 'Пароли не совпадают' })
        .required({ text: 'Обязательное поле' }),
});
