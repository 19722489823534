import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { useLocation, Redirect, matchPath, } from 'react-router-dom';
import SideBar from '@/widgets/ui/SideBar/SideBar';
import { getProfile, setToken } from '@/entity/ProfileEntity/profile.slice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { setupGlobalStyles, Main, MainContainer } from '@/app/styles/mainStyles';
import { Toaster } from 'react-hot-toast';
import { SuspenseLoader } from '@/shared/ui/SuspenseLoader/SuspenseLoader';
import Cookies from 'universal-cookie';
import { Modals } from '@/widgets/ui/Modals/Modals';
import { useMedia } from 'react-use';
import { MobileSideBar } from '@/widgets/ui/SideBar/mobile/MobileSideBar';
import { getSavedProducts } from '@/entity/ListSavedProductsEntity/savedProducts.slice';
import { getUserSubscription } from '@/entity/PaymentEntity/payment.slice';
import { getMyShops } from '@/entity/MailingShopsEntity/shops.slice';
import { AppRouter } from '@/app/providers/router/ui/AppRouter';
import { getBalanceForMailings } from '@/entity/MailingListEntity/mailing.slice';
import { getAllCalculation } from '@/entity/CalculatorEntity/calculator.slice';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
moment.locale('ru');
const cookies = new Cookies();
const App = () => {
    setupGlobalStyles();
    const isDesktop = useMedia('(min-width: 1025px)', true);
    const location = useLocation();
    const token = useTypedSelector((state) => state.profileReducer.token);
    const profile = useTypedSelector((state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.profileReducer) === null || _a === void 0 ? void 0 : _a.profile; });
    const dispatch = useTypedDispatch();
    const [isSidebarOpened, setIsSidebarOpened] = useState(false);
    const [isTransition, setIsTransition] = useState(false);
    const mathParamsLogin = matchPath(location.pathname, {
        path: '/login',
    });
    const matchParamsRegister = matchPath(location.pathname, {
        path: '/signup',
    });
    const width = (mathParamsLogin === null || mathParamsLogin === void 0 ? void 0 : mathParamsLogin.isExact) || (matchParamsRegister === null || matchParamsRegister === void 0 ? void 0 : matchParamsRegister.isExact);
    useEffect(() => {
        if (!cookies.get('token')) {
            dispatch(setToken());
        }
        const sidebarOpened = localStorage.getItem('sidebarOpened');
        if (sidebarOpened) {
            if (JSON.parse(sidebarOpened)) {
                if (isDesktop) {
                    setIsSidebarOpened(true);
                }
                else {
                    setIsSidebarOpened(false);
                }
                setTimeout(() => {
                    setIsTransition(true);
                }, 300);
            }
            else {
                setIsTransition(true);
            }
        }
        else {
            if (isDesktop) {
                setIsSidebarOpened(true);
            }
            else {
                setIsSidebarOpened(false);
            }
        }
    }, [isDesktop]);
    useEffect(() => {
        if (profile) {
            if ((profile === null || profile === void 0 ? void 0 : profile.kind) !== 'USER_KIND_UNVERIFIED') {
                if (profile.products.includes('USER_ACTIVE_PRODUCT_MAILING')) {
                    dispatch(getMyShops());
                    dispatch(getBalanceForMailings());
                }
                if (profile.products.includes('USER_ACTIVE_PRODUCT_CALCULATOR')) {
                    dispatch(getUserSubscription());
                    dispatch(getSavedProducts({ limit: 500, offset: 0 }));
                    dispatch(getAllCalculation({ limit: 500, offset: 0 }));
                }
            }
        }
    }, [profile]);
    useEffect(() => {
        if (token) {
            dispatch(getProfile());
        }
    }, [token]);
    if ((location === null || location === void 0 ? void 0 : location.pathname) === '/') {
        return _jsx(Redirect, { to: RoutePath.main });
    }
    return (_jsx(MainContainer, { authPage: width, children: _jsxs(Main, { pageFullWidth: width, children: [isDesktop ?
                    _jsx(SideBar, { isDesktop: isDesktop, setIsOpened: setIsSidebarOpened, isOpened: isSidebarOpened, userKind: profile === null || profile === void 0 ? void 0 : profile.kind })
                    :
                        _jsx(MobileSideBar, { isDesktop: isDesktop, setIsOpened: setIsSidebarOpened, isOpened: isSidebarOpened, userKind: profile === null || profile === void 0 ? void 0 : profile.kind }), _jsx(Suspense, { fallback: _jsx(SuspenseLoader, {}), children: _jsx(AppRouter, { profile: profile, isTransition: isTransition, isSidebarOpened: isSidebarOpened, width: width }) }), _jsx(Toaster, { position: isDesktop ? 'top-right' : 'top-center' }), _jsx(Modals, { setIsOpened: setIsSidebarOpened, isDesktop: isDesktop })] }) }));
};
export { App };
