export var SelectValues;
(function (SelectValues) {
    SelectValues["ALL_USERS"] = "ALL_USERS";
    SelectValues["DEFINED_USERS"] = "DEFINED_USERS";
    SelectValues["LIMITED_ACTIVATIONS"] = "LIMITED_ACTIVATIONS";
})(SelectValues || (SelectValues = {}));
export const prepareCreateInvite = (inviteCode, type, mailingRule, calculatorRule, emailsIds) => {
    const copy = Object.assign({}, inviteCode);
    if (type === SelectValues.ALL_USERS) {
        copy.bonusUserEmails = [];
        copy.activationLimit = null;
    }
    if (type === SelectValues.LIMITED_ACTIVATIONS) {
        copy.bonusUserEmails = [];
    }
    if (type === SelectValues.DEFINED_USERS) {
        copy.bonusUserEmails = emailsIds.split(', ');
        copy.activationLimit = null;
    }
    if (mailingRule && calculatorRule) {
        return copy;
    }
    if (mailingRule && !calculatorRule) {
        copy.calculatorRule = null;
        return copy;
    }
    if (!mailingRule && calculatorRule) {
        copy.mailingRule = null;
        return copy;
    }
    return copy;
};
