export var PromocodesActionTypesEnum;
(function (PromocodesActionTypesEnum) {
    PromocodesActionTypesEnum["PROMOCODE_APPLY_TYPE_NONE"] = "PROMOCODE_APPLY_TYPE_NONE";
    PromocodesActionTypesEnum["PROMOCODE_APPLY_TYPE_FOR_ALL"] = "PROMOCODE_APPLY_TYPE_FOR_ALL";
    PromocodesActionTypesEnum["PROMOCODE_APPLY_TYPE_REPEATEDLY"] = "PROMOCODE_APPLY_TYPE_REPEATEDLY";
    PromocodesActionTypesEnum["PROMOCODE_APPLY_TYPE_ON_IDS"] = "PROMOCODE_APPLY_TYPE_ON_IDS";
    PromocodesActionTypesEnum["PROMOCODE_APPLY_TYPE_DISABLE"] = "PROMOCODE_APPLY_TYPE_DISABLE";
})(PromocodesActionTypesEnum || (PromocodesActionTypesEnum = {}));
export var PromocodesApplyTypesLocaleEnum;
(function (PromocodesApplyTypesLocaleEnum) {
    PromocodesApplyTypesLocaleEnum["PROMOCODE_APPLY_TYPE_NONE"] = "\u0411\u0435\u0437 \u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0445 \u0443\u0441\u043B\u043E\u0432\u0438\u0439";
    PromocodesApplyTypesLocaleEnum["PROMOCODE_APPLY_TYPE_FOR_ALL"] = "\u0411\u0435\u0437 \u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0445 \u0443\u0441\u043B\u043E\u0432\u0438\u0439";
    PromocodesApplyTypesLocaleEnum["PROMOCODE_APPLY_TYPE_REPEATEDLY"] = "\u0421 \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u0435\u043C \u043D\u0430 \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0439";
    PromocodesApplyTypesLocaleEnum["PROMOCODE_APPLY_TYPE_ON_IDS"] = "\u0414\u043B\u044F \u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u043D\u044B\u0445 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439";
    PromocodesApplyTypesLocaleEnum["PROMOCODE_APPLY_TYPE_DISABLE"] = "disable";
})(PromocodesApplyTypesLocaleEnum || (PromocodesApplyTypesLocaleEnum = {}));
