import { routes } from '@/shared/utils/routes';
// import { ReactComponent as Home } from '@/shared/assets/icons/home.svg';
// import { ReactComponent as Page } from '@/shared/assets/icons/page.svg';
import { ReactComponent as Profile } from '@/shared/assets/icons/profile.svg';
import { UserKindEnum } from '@/app/interfaces/Mailings/types';
const firstBlockButtons = [
    // {
    //   id: 'main',
    //   icon: Home,
    //   text: 'Главная',
    //   route: routes.home,
    //   isMain: true,
    // },
    // {
    //   id: 'notifications',
    //   icon: Bell,
    //   text: 'Мои уведомления',
    //   route: routes.notifications,
    // },
    // {
    //   id: 'page',
    //   icon: Page,
    //   text: 'Лента',
    //   route: routes.home,
    // },
    {
        id: 'profile',
        icon: Profile,
        text: 'Мой аккаунт',
        role: [UserKindEnum.USER_KIND_DEFAULT, UserKindEnum.USER_KIND_ADMIN],
        route: routes.profile,
        notification: true,
    },
];
export default firstBlockButtons;
