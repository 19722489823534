import { styled } from '@/app/styles/mainStyles';
import Search from '@/shared/assets/icons/searchIcon.svg';
import { Text } from '@/shared/ui/Text/style';
export const Input = styled('input', {
    background: '$white',
    border: '1px solid $border',
    borderRadius: '8px',
    fontWeight: 400,
    paddingLeft: '6px',
    padding: '4px 11px',
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '14px',
    lineHeight: 1.5715,
    '&:hover': {
        outline: 'none',
        boxShadow: '0px 0px 0px 1px #B3B8E7',
        transition: '0.4s',
    },
    '&:disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
    },
    '&:focus-visible': {
        outline: 'none',
    },
    '&::placeholder': {
        fontFamily: '$nunito',
    },
    variants: {
        typeInput: {
            default: {
                height: '40px',
                width: '100%',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #B3B8E7',
                },
            },
            small: {
                height: '32px',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #B3B8E7',
                },
            },
            auth: {
                height: '32px',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #B3B8E7',
                },
                '@mobile': {
                    width: '100%',
                },
                '@desktop': {
                    width: '544px',
                },
            },
        },
        error: {
            true: {
                outline: 'none',
                border: '1px solid $textError',
                transition: '0.5s',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #F1AFAFA5',
                },
                '&:hover': {
                    boxShadow: '0px 0px 0px 1px #F1AFAFA5',
                    transition: '0.3s',
                },
            },
            errorLogistic: {
                outline: 'none',
                border: '1.5px solid $logisticError',
                transition: '0.5s',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #E38E8E',
                },
                '&:hover': {
                    boxShadow: '0px 0px 0px 1px #E38E8E',
                    transition: '0.3s',
                },
            },
        },
        iconInside: {
            true: {
                backgroundImage: `url(${Search})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '18px 18px',
                backgroundPosition: 'left 6px center',
                padding: '10px 0 8px 30px',
            },
        },
    },
});
export const TextLabel = styled(Text, {
    variants: {
        fontType: {
            nunito: {
                fontFamily: '$nunito',
                fontWeight: '700',
                fontSize: '16px',
                marginBottom: '8px',
                lineHeight: '21px',
            },
            inter: {
                fontFamily: '$inter',
                fontWeight: '700',
                fontSize: '17px',
                lineHeight: '21px',
                marginBottom: '8px',
            },
        },
    },
});
export const Label = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
});
