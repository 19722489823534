import { UserKindEnum } from '@/app/interfaces/Mailings/types';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
const adminBlockButtons = [
    {
        id: 'promocodes',
        text: 'Промокоды',
        role: [UserKindEnum.USER_KIND_ADMIN],
        route: RoutePath.promoCodes,
    },
    {
        id: 'invite-codes',
        text: 'Инвайт-коды',
        role: [UserKindEnum.USER_KIND_ADMIN],
        route: RoutePath.inviteCodes,
    },
    {
        id: 'add-messages',
        role: [UserKindEnum.USER_KIND_ADMIN],
        text: 'Начислить сообщения',
    },
];
export default adminBlockButtons;
