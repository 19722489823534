import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import DatePickerPrimitive, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import ru from 'date-fns/locale/ru';
import * as S from './style';
import { Space } from '@/shared/ui/Space/Space';
import { Text } from '@/shared/ui/Text/Text';
import moment from 'moment';
import { DatePickerInput } from '@/shared/ui/DatePickerInput/DatePickerInput';
import { Select, Switch } from '@/shared/ui';
import { iconDictionary } from '@/shared/iconDictionary/icon-dictionray';
import { getMonth, getYear } from 'date-fns';
registerLocale('ru', ru);
function getYearsArray(minDate, maxDate) {
    var _a, _b;
    if (!minDate || !maxDate) {
        return [];
    }
    const startYear = (_a = new Date(minDate)) === null || _a === void 0 ? void 0 : _a.getFullYear(); // Начальный год
    const endYear = (_b = new Date(maxDate)) === null || _b === void 0 ? void 0 : _b.getFullYear(); // Конечный год
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }
    return years;
}
const monthDateOptions = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];
function getSelectableMonths(minDate, maxDate, currentYear) {
    var _a, _b, _c, _d;
    if (!minDate || !maxDate) {
        return monthDateOptions;
    }
    const minYear = (_a = new Date(minDate)) === null || _a === void 0 ? void 0 : _a.getFullYear();
    const maxYear = (_b = new Date(maxDate)) === null || _b === void 0 ? void 0 : _b.getFullYear();
    const minMonth = (_c = new Date(minDate)) === null || _c === void 0 ? void 0 : _c.getMonth(); // Месяц начальной даты (0 - январь)
    const maxMonth = (_d = new Date(maxDate)) === null || _d === void 0 ? void 0 : _d.getMonth(); // Месяц конечной даты (0 - январь)
    // По умолчанию все месяцы доступны
    let startMonth = 0;
    let endMonth = 11;
    // Если выбранный год совпадает с годом начальной даты (minDate)
    if (currentYear === minYear) {
        startMonth = minMonth;
    }
    // Если выбранный год совпадает с годом конечной даты (maxDate)
    if (currentYear === maxYear) {
        endMonth = maxMonth;
    }
    // Если выбранный год между minYear и maxYear, возвращаем все месяцы
    if (currentYear > minYear && currentYear < maxYear) {
        startMonth = 0;
        endMonth = 11;
    }
    // Если выбранный год — следующий после minYear и диапазон должен быть ограничен 12 месяцами
    if (currentYear === minYear + 1 && minYear < maxYear) {
        endMonth = Math.min(11, minMonth + 11); // Ограничиваем 12 месяцами от minDate
        if (maxMonth <= endMonth) {
            endMonth = maxMonth; // Включаем maxMonth, если он находится в пределах одного года
        }
    }
    // Возвращаем доступные месяцы с startMonth до endMonth включительно
    return monthDateOptions.slice(startMonth, endMonth + 1);
}
const Header = ({ monthDate, maxDate, startDate, changeYear, minDate, customHeaderCount, decreaseMonth, increaseMonth, canFuture, isDesktop, changeMonth, type, }) => {
    const years = useMemo(() => getYearsArray((startDate && customHeaderCount === 1) ? startDate : minDate, maxDate), [minDate, maxDate, startDate]);
    const month = useMemo(() => {
        if (canFuture) {
            return monthDateOptions;
        }
        return getSelectableMonths((startDate && customHeaderCount === 1) ? startDate : minDate, maxDate, monthDate.getFullYear());
    }, [maxDate, minDate, monthDate, startDate]);
    const disableButtonFuture = useMemo(() => {
        if (canFuture) {
            return true;
        }
        if (maxDate) {
            return monthDate < maxDate;
        }
        return monthDate < new Date();
    }, [monthDate, maxDate]);
    return (_jsxs(Space, { css: { padding: '16px 16px 8px 16px', borderRadius: '16px' }, content: "between", align: "center", children: [customHeaderCount === 0 && (_jsx("div", { style: { cursor: 'pointer' }, onClick: decreaseMonth, children: iconDictionary.DATELEFT })), customHeaderCount === 1 && _jsx("div", { style: { width: 40 } }), _jsx(Select, { type: "default", css: { width: '120px', height: '34px', margin: 0 }, value: monthDateOptions[getMonth(monthDate)], onValueChange: (value) => {
                    changeYear(getYear(monthDate));
                    changeMonth(customHeaderCount === 0 ? monthDateOptions.indexOf(value) : monthDateOptions.indexOf(value) - 1);
                }, children: month.map((el) => {
                    return (_jsx(Select.Item, { name: el, value: el, children: el }, el));
                }) }), _jsx(Select, { type: "default", css: { width: '88px', height: '34px', margin: 0 }, value: getYear(monthDate), onValueChange: (value) => {
                    changeYear(value);
                }, children: years.map((el) => {
                    return (_jsx(Select.Item, { name: el, value: el, children: el }, el));
                }) }), (customHeaderCount === 0 && type !== 'promocode' && type !== 'mailing') && _jsx("div", { style: { width: 40 } }), (customHeaderCount === 1 || !isDesktop || type === 'promocode' || type === 'mailing') && (_jsx("div", { style: { cursor: 'pointer' }, onClick: () => {
                    if (disableButtonFuture) {
                        increaseMonth();
                    }
                }, children: iconDictionary.DATERIGHT }))] }));
};
const CustomInput = forwardRef(({ value, onClick, type, setChecked, checked }, ref) => {
    let startDate = '';
    let endDate = '';
    let mailingDate = '';
    const checkChecked = typeof checked === 'undefined' ? true : checked;
    if (value && checkChecked) {
        const [firstValue, secondValue] = value.split(' - ');
        startDate = moment(firstValue).format('D MMM YYYY');
        endDate = moment(secondValue).format('D MMM YYYY');
    }
    if (!checkChecked && value) {
        endDate = moment(value).format('D MMM YYYY');
    }
    if (type === 'mailing' && value) {
        mailingDate = moment(value).format('D MMM YYYY');
    }
    return (_jsxs(Space, { css: { width: '100%' }, align: "center", children: [type === 'mailing' && (_jsx(DatePickerInput, { value: mailingDate === 'Invalid date' ? '' : mailingDate, ref: ref, onClick: onClick })), type === 'default' && (_jsxs(_Fragment, { children: [_jsx(DatePickerInput, { value: startDate === 'Invalid date' ? '' : startDate, ref: ref, onClick: onClick }), _jsx(Text, { css: { margin: '0 4px' }, type: "labelText", children: "-" }), _jsx(DatePickerInput, { value: endDate === 'Invalid date' ? '' : endDate, ref: ref, onClick: onClick })] })), type === 'promocode' && (_jsxs(Space, { size: "large", children: [_jsxs(Space, { size: "default", direction: "vertical", children: [_jsxs(Space, { size: "default", children: [_jsx(Switch, { color: "green", checked: checked, setChecked: setChecked }), _jsx(Text, { disable: !checked, children: "\u0414\u0430\u0442\u0430 \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0438" })] }), checked ?
                                _jsx(DatePickerInput, { value: startDate === 'Invalid date' ? '' : startDate, ref: ref, onClick: onClick }) :
                                _jsx(Text, { css: { color: '$acceptButton' }, children: "\u0414\u043E\u0441\u0442\u0443\u043F\u0435\u043D \u0441\u0440\u0430\u0437\u0443" })] }), _jsxs(Space, { size: "default", direction: "vertical", children: [_jsx(Text, { children: "\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F" }), _jsx(DatePickerInput, { value: endDate === 'Invalid date' ? '' : endDate, ref: ref, onClick: onClick })] })] }))] }));
});
const Container = ({ children }) => {
    return (_jsx(Space, { css: {
            background: 'white',
            boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
            border: '1px solid $border',
            borderRadius: 16,
            height: 384,
            overflow: 'hidden',
        }, size: "small", children: _jsx("div", { children: _jsx(Space, { children: children }) }) }));
};
const renderDayContents = (day) => {
    return (_jsx("div", { style: { width: '100%', borderRadius: '50%' }, children: day }));
};
export const DatePicker = (props) => {
    const isDesktop = window.innerWidth >= 1025;
    const { startDate, handleClickDatePicker, endDate, error, onChange, canFuture = false, minDateValue, errorMessage, onBlur, disabled, maxDate, type = 'default', checked, setChecked, selected, } = props;
    const currentDate = new Date();
    const selectRange = typeof checked === 'undefined' ? true : checked;
    const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
    const getMonthShow = useMemo(() => {
        if (type === 'promocode' || type === 'mailing') {
            return 1;
        }
        return isDesktop ? 2 : 1;
    }, []);
    const isError = () => typeof error === 'object';
    return (_jsxs(S.Wrapper, { children: [_jsx(DatePickerPrimitive, { onInputClick: handleClickDatePicker, customInput: _jsx(CustomInput, { type: type, checked: checked, setChecked: setChecked }), monthsShown: getMonthShow, locale: "ru", selected: selected, minDate: minDateValue ? minDateValue : minDate, maxDate: maxDate ? maxDate : new Date(), selectsRange: selectRange, startDate: startDate, endDate: endDate, renderCustomHeader: (propsHeader) => (_jsx(Header, Object.assign({ maxDate: maxDate, minDate: minDateValue, type: type, canFuture: canFuture, isDesktop: isDesktop, startDate: startDate }, propsHeader))), calendarContainer: (datePickerProps) => (_jsx(Container, Object.assign({}, datePickerProps))), popperPlacement: isDesktop ? 'top-start' : 'top-end', renderDayContents: renderDayContents, onChange: onChange, onBlur: onBlur, disabled: disabled, showPopperArrow: false, focusSelectedMonth: true }), isError() && _jsx(S.Error, { children: errorMessage })] }));
};
