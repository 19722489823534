import { jsx as _jsx } from "react/jsx-runtime";
import { Route, Switch } from 'react-router-dom';
import React, { Suspense } from 'react';
import { routeConfig } from '@/shared/config/routeConfig/routeConfig';
import { SuspenseLoader } from '@/shared/ui/SuspenseLoader/SuspenseLoader';
import { WithAuth } from '@/shared/hocs/withAuth/withAuth';
import { MainContentContainer } from '@/app/styles/mainStyles';
import { SendCodeEnum } from '@/features/ui/Dialogs/AuthFlow/SendCodeStep/types';
import { SendCodeStep } from '@/features/ui/Dialogs/AuthFlow/SendCodeStep/SendCodeStep';
import { UserKindEnum } from '@/app/interfaces/Mailings/types';
import { NotFoundPage } from '@/pages';
import { useCheckPaymentContext } from '@/app/providers/context/checkPaymentContext/checkPaymentContext';
import ErrorBoundary from '@/shared/hocs/ErrorBoundary/ErrorBoundary';
const AppRouter = ({ profile, isTransition, width, isSidebarOpened }) => {
    useCheckPaymentContext();
    const getPermissions = (type, permissions) => {
        var _a;
        switch (type) {
            case 'Product':
                return (_a = profile === null || profile === void 0 ? void 0 : profile.products) === null || _a === void 0 ? void 0 : _a.find((el) => el === permissions);
            case 'Role':
                return profile === null || profile === void 0 ? void 0 : profile.kind.includes(permissions);
            default:
                return false;
        }
    };
    return (_jsx(Suspense, { fallback: _jsx(SuspenseLoader, {}), children: (profile === null || profile === void 0 ? void 0 : profile.kind) === UserKindEnum.USER_KIND_UNVERIFIED ?
            _jsx(SendCodeStep, { email: profile === null || profile === void 0 ? void 0 : profile.email, codeVerify: SendCodeEnum.VerifyTypeOnboard, action: "VerifyAccount" })
            :
                _jsx(MainContentContainer, { isTransition: isTransition, isSidebarOpened: !isSidebarOpened, register: width, children: _jsx(ErrorBoundary, { children: _jsx(Switch, { children: Object.values(routeConfig).map(({ id, element, path, isProtect, permissions, }) => {
                                if (isProtect) {
                                    if (permissions) {
                                        const findPermissions = getPermissions(permissions.type, permissions.value);
                                        if (!findPermissions) {
                                            return _jsx(Route, { exact: true, path: path, render: () => (_jsx(WithAuth, { children: _jsx(NotFoundPage, {}) })) }, id);
                                        }
                                    }
                                    return _jsx(Route, { exact: true, path: path, render: () => (_jsx(WithAuth, { children: element })) }, id);
                                }
                                else {
                                    return _jsx(Route, { exact: true, path: path, render: () => element }, id);
                                }
                            }) }) }) }) }));
};
export { AppRouter };
